// @flow

import StickyInlines from 'slate-sticky-inlines';
// import StickyInlines from './sticky-inline';
import HardBreak from './hard-break';
import SoftBreak from './soft-break';
import ResetBlock from './reset-block';
import CollapseOnEscape from './collapse-selection';

// function TrailingBlock(
// 	{ type }: { type: string } = { type: 'paragraph' }
// ): Plugin {
// 	return {
// 		normalizeNode: (node: Node, next: Next) => {
// 			if (node.object === 'document' && node.nodes.last().type !== type) {
// 				const lastIndex = node.nodes.count();
// 				const block = Block.create({
// 					type,
// 					nodes: [Text.create()]
// 				});
// 				return (editor: Editor) =>
// 					editor.insertNodeByKey(node.key, lastIndex, block);
// 			}
// 			return next();
// 		}
// 	};
// }

const globalPlugins = [
	StickyInlines({
		// allowedTypes: [],
		// bannedTypes: [],
		canBeEmpty: false,
		hasStickyBoundaries: true,
		stickOnDelete: true
	}),
	HardBreak(),
	SoftBreak(),
	ResetBlock(),
	CollapseOnEscape()
];

export default globalPlugins;
