// @flow

import * as React from 'react';
import { Card, H1, Button } from '@blueprintjs/core';
import { GoogleIcon } from './Icons';
import * as types from './types';

export const Login = ({ handleLogin }: { handleLogin: types.HandleLogin }) => (
	<div className="content">
		<div className="doc markdown">
			<Card className="center">
				<img src="/images/icon.png" alt="icon" width={128} />
				<H1>Welcome to Dynamic Docs</H1>
				<p>
					Dynamic Docs is a proof-of-concept that brings the power of
					interactive computing to the common doc (e.g. Google Docs) to cater to
					and empower a less technical but much larger category of users.
				</p>
				<Button
					text="Login with Google"
					icon={GoogleIcon}
					onClick={handleLogin}
					large
					// loading={loading}
				/>
			</Card>
		</div>
	</div>
);
