// @flow

import { binder } from 'rx-binder';
import { kernels } from 'rx-jupyter';
import { of } from 'rxjs';
import { tap, map, catchError, filter } from 'rxjs/operators';
import * as types from '../../types';

const mybinderURL = 'https://mybinder.org';

function createKey({
	repo = 'nteract/vdom',
	ref = 'master',
	binderURL = mybinderURL
}: types.BinderOptions) {
	return `${binderURL}/${repo}#${ref}`;
}

function createHost({
	endpoint,
	token
}: {
	endpoint: string,
	token: string
}): types.CachedHost {
	return {
		active: true,
		config: {
			crossDomain: true,
			endpoint,
			token
		}
	};
}

function getCachedHost(options: types.BinderOptions): ?types.CachedHost {
	try {
		return JSON.parse(window.localStorage[createKey(options)]);
	} catch (error) {
		return null;
	}
}

function setCachedHost(options: types.BinderOptions, host: types.CachedHost) {
	localStorage[createKey(options)] = JSON.stringify(host, null, 2);
}

// function sleep(duration) {
// 	return new Promise(resolve => setTimeout(resolve, duration));
// }

export async function getHost(
	options: types.BinderOptions
): Promise<types.BinderHost> {
	let cachedHost = getCachedHost(options);
	if (cachedHost && cachedHost.active) {
		const active = await kernels
			.list(cachedHost.config)
			.pipe(
				map(xhr => {
					console.log(xhr);
					return true;
				}),
				catchError(err => {
					return of(false);
				})
			)
			.toPromise();
		if (active) return cachedHost.config;
	}
	// while (!cachedHost || !cachedHost.active) {
	// 	await sleep(1000);
	// 	cachedHost = getCachedHost(options);
	// 	if (cachedHost && cachedHost.active) {
	// 		return cachedHost.config;
	// 	}
	// }

	const host = await binder(options)
		.pipe(
			tap(x => {
				console.log(x);
			}),
			filter(msg => msg.phase === 'ready'),
			map(msg => createHost({ endpoint: msg.url, token: msg.token }))
		)
		.toPromise();
	setCachedHost(options, host);
	return host.config;
}
