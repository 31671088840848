// @flow

import * as React from 'react';
import type { Editor, Next } from '../../types';

export function MarkHotkeys() {
	return {
		onKeyDown: (
			event: SyntheticKeyboardEvent<*>,
			editor: Editor,
			next: Next
		) => {
			if (event.metaKey) {
				switch (event.key) {
					case 'i': {
						event.preventDefault();
						return editor.toggleMark('italic');
					}
					case 'b': {
						event.preventDefault();
						return editor.toggleMark('bold');
					}
					case 'u': {
						event.preventDefault();
						return editor.toggleMark('underline');
					}
					default: {
						return next();
					}
				}
			}
			return next();
		}
	};
}

const hotkeyPlugins = [MarkHotkeys()];

export default hotkeyPlugins;
