// @flow

import * as types from '../types';

export const languages: types.Language[] = [
	{ name: 'python', displayName: 'Python' },
	{ name: 'r', displayName: 'R' },
	{ name: 'julia', displayName: 'Julia' },
	{ name: 'javascript', displayName: 'Javascript' },
	{ name: 'nodejs', displayName: 'Node.js' },
	{ name: 'bash', displayName: 'Bash' }
];
