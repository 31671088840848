// @flow

import * as React from 'react';
import { RedBoxError as RedBox } from 'redbox-react';
import type { ChildrenProps, ErrorBoundaryState } from '../../types';

export default class ErrorBoundary extends React.Component<
	ChildrenProps,
	ErrorBoundaryState
> {
	state = { error: null };

	componentDidUpdate(
		prevProps: ChildrenProps,
		prevState: ErrorBoundaryState,
		snapshot: any
	) {
		if (prevProps.children !== this.props.children) {
			this.setState({ error: null });
		}
	}

	componentDidCatch(error: Error, info: { componentStack: string }) {
		const { name } = error;
		const message = error.message.replace(/\n.*/g, '');
		const stack = info.componentStack.replace(
			/in (.+) \(at (.+)\)/gm,
			'at $1 ($2)'
		);
		this.setState({ error: { name, message, stack } });
	}

	render() {
		const style = {
			redbox: {
				boxSizing: 'border-box',
				fontFamily: 'sans-serif',
				padding: 10,
				top: '0px',
				left: '0px',
				bottom: '0px',
				right: '0px',
				width: '100%',
				background: 'rgb(204, 0, 0)',
				color: 'white',
				textAlign: 'left',
				fontSize: '16px',
				lineHeight: 1.2,
				position: 'relative',
				overflow: 'scroll',
				maxheight: 480,
				zIndex: 0
			}
		};
		if (this.state.error) {
			return <RedBox error={this.state.error} style={style} />;
		}
		if (this.props.children instanceof Error) {
			return <RedBox error={this.props.children} style={style} />;
		}
		if (
			React.isValidElement(this.props.children) ||
			['string', 'number', 'boolean'].includes(typeof this.props.children) ||
			this.props.children === null
		) {
			return this.props.children;
		}
		if (this.props.children.toString) return this.props.children.toString();
		return null;
	}
}
