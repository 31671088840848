// @flow

import * as React from 'react';
import { toTitleCase } from '../utils';
import type { Editor, Next } from '../../types';

export default function CollapseOnEscape(
	{ toEdge }: { toEdge: 'start' | 'end' | 'anchor' | 'focus' } = {
		toEdge: 'end'
	}
) {
	return {
		onKeyDown(event: SyntheticKeyboardEvent<*>, editor: Editor, next: Next) {
			if (event.key === 'Escape' && !editor.value.selection.isCollapsed) {
				const edge = toTitleCase(toEdge);
				return editor[`moveTo${edge}`]();
			}
			return next();
		}
	};
}
