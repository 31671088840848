// @flow

import * as React from 'react';
import { getEventTransfer } from 'slate-react';
import { isUrl, toTitleCase } from '../utils';
import type { Editor, Next, Value } from '../../types';

export default function PasteLink(
	{
		type,
		hrefProperty,
		collapseTo
	}: { type: string, hrefProperty: string, collapseTo: string } = {
		type: 'link',
		hrefProperty: 'href',
		collapseTo: 'end'
	}
) {
	function hasLinks(value: Value) {
		return value.inlines.some(inline => inline.type === type);
	}

	function unwrapLink(editor: Editor) {
		editor.unwrapInline(type);
	}

	function wrapLink(editor: Editor, href: string) {
		editor.wrapInline({
			type,
			data: { [hrefProperty]: href }
		});
	}

	return {
		onKeyDown: (
			event: SyntheticKeyboardEvent<*>,
			editor: Editor,
			next: Next
		) => {
			const { value } = editor;
			const { text } = value.startText;
			if (
				event.key === ' ' &&
				event.key === 'Enter' &&
				isUrl(text) &&
				!editor.value.focusText.text.match(/!?\[(?:.*)?\]\(/)
			) {
				editor.moveAnchorBackward(text.length);
				if (hasLinks(value)) editor.command(unwrapLink);
				editor.command(wrapLink, text);
				editor.moveToEnd();
				return editor;
			}
			return next();
		},
		onPaste(event: SyntheticEvent<ClipboardEvent>, editor: Editor, next: Next) {
			const transfer = getEventTransfer(event);
			const { value } = editor;
			const { type, text } = transfer;
			if (
				(type === 'text' || type === 'html') &&
				isUrl(text) &&
				!editor.value.focusText.text.match(/!?\[(?:.*)?\]\(/)
			) {
				const { selection } = value;
				if (selection.isCollapsed) {
					const { offset } = selection.start;
					editor
						.insertText(text)
						.moveAnchorTo(offset)
						.moveFocusTo(offset + text.length);
				} else if (hasLinks(value)) {
					editor.command(unwrapLink);
				}
				editor.command(wrapLink, text);
				if (collapseTo) {
					editor[`moveTo${toTitleCase(collapseTo)}`]();
				}
				return editor;
			}
			return next();
		}
	};
}
