// @flow

export function copyToClipboard(text: string): void {
	const copyElement = document.createElement('input');
	copyElement.value = text;
	document.body.appendChild(copyElement);
	copyElement.select();
	document.execCommand('copy');
	document.body.removeChild(copyElement);
}

export function resolveLanguage(language: string): string {
	switch (language) {
		case 'python':
		case 'py':
			return 'python';
		case 'node':
		case 'nodejs':
			return 'nodejs';
		case 'javascript':
		case 'js':
		case 'jsx':
			return 'javascript';
		case 'bash':
		case 'shell':
			return 'bash';
		case 'r':
			return 'r';
		case 'julia':
		case 'jl':
			return 'julia';
		default:
			return 'javascript';
	}
}
