// @flow

// import babylon from 'babylon';

const { Babel } = window;

Babel.registerPlugin(
	'syntax-dynamic-import',
	require('babel-plugin-syntax-dynamic-import')
);

function asyncify(input: string): string {
	return `(
		async function() {
			try {
				${input}
			} catch(error) {
				throw error;
			}
			
		}
	)()`;
}

function returnify(ast: Object): Object {
	function getLastExpression(ast: Object): Object {
		const body = ast.program.body[ast.program.body.length - 1];
		const tryStatement = body.expression.callee.arguments[0].body.body[0];
		const blocks = tryStatement.block.body;
		const lastExpression = blocks[blocks.length - 1];
		return lastExpression;
	}
	let lastExpression = getLastExpression(ast);
	if (!lastExpression) return ast;
	lastExpression.type = 'ReturnStatement';
	if (lastExpression.expression) {
		lastExpression.argument = lastExpression.expression;
		delete lastExpression.expression;
	}
	return ast;
}

function babelify(input: string): string {
	let { ast, code } = Babel.transform(input, {
		presets: ['react', 'stage-0'],
		plugins: ['syntax-dynamic-import']
	});
	// const ast = babylon.parse(code, { allowReturnOutsideFunction: true });
	// const { code, map, ast } = babel.transformFromAst(ast, code, options);
	return Babel.transformFromAst(returnify(ast), code, {
		presets: ['react', 'stage-0'],
		plugins: ['syntax-dynamic-import']
	}).code;
}

export default function transform(input: string): string {
	return babelify(asyncify(input));
}
