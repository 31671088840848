// @flow

import * as React from 'react';
import { displayOrder } from '@nteract/transforms-full';
import { Output } from '@nteract/display-area';
import { ContextMenuTarget, Menu, ProgressBar } from '@blueprintjs/core';
import { getBinder, createMessage } from './binder';
import ErrorBoundary from './ErrorBoundary';
import getContext from './context';
import * as types from '../types';

const defaultState = {
	parent: null,
	messages: {},
	output: null,
	status: 'starting',
	transforms: []
};

class CodeOutput extends React.Component<
	types.CodeOutputProps,
	types.CodeOutputState
> {
	state = defaultState;

	kernel: Promise<types.Kernel>;

	subscription: types.Subscription;

	output = HTMLDivElement;

	async componentDidMount() {
		this.getRuntime();
		const transforms = await import('@nteract/transforms-full').then(({transforms}) => transforms);
		this.setState({transforms});
	}

	componentDidUpdate(prevProps: types.CodeOutputProps) {
		if (prevProps.language !== this.props.language) {
			this.getRuntime();
		}
	}

	componentWillUnmount() {
		if (this.subscription) this.subscription.unsubscribe();
	}

	getRuntime = async () => {
		const { source, language } = this.props;
		if (language === 'javascript') {
			this.eval();
		} else if (language !== '') {
			try {
				this.kernel = await getBinder(language);
				if (this.subscription) this.subscription.unsubscribe();
				this.subscription = this.kernel.channels.subscribe(
					msg => {
						console.log('msg', msg);
						let { parent } = this.state;
						if (msg.parent_header && msg.parent_header.msg_id) {
							if (
								msg.msg_type === 'execute_input' &&
								msg.content.code === source
							) {
								parent = msg.parent_header.msg_id;
								this.setState({ parent });
							}
							if (parent && msg.parent_header.msg_id === parent) {
								this.setState(({ messages, output, status }) => {
									messages = {
										...messages,
										[parent]: [...(messages[parent] || []), msg]
									};
									if (
										[
											'execute_result',
											'display_data',
											'update_display_data',
											'stream',
											'error'
										].includes(msg.msg_type)
									) {
										output = {
											output_type: msg.msg_type,
											...msg.content
										};
									}
									if (msg.msg_type === 'status') {
										status = msg.content.execution_state;
									}
									return { messages, output, status };
								});
							}
						}
					},
					err => {
						console.error('msg', err);
					}
				);
				this.execute();
			} catch (error) {
				console.error(error);
			}
		}
	};

	execute = async (): Promise<void> => {
		try {
			const { source } = this.props;
			const kernel = await this.kernel;
			kernel.channels.next(createMessage(source));
		} catch (error) {
			console.error(error);
		}
	};

	eval = async (): Promise<void> => {
		const { source, getValue, getBlockData, setBlockData } = this.props;
		const block = {
			get: (key: string): Object => {
				const savedData = (getBlockData(key): string);
				if (savedData) return JSON.parse(savedData);
				return {};
			},
			set: (key: string, data: any): void => {
				const savedData = getBlockData(key);
				const serializedData = JSON.stringify(data);
				if (serializedData !== savedData) {
					setBlockData({ [key]: serializedData });
				}
			}
		};
		const content = {
			get: (): Object => {
				return getValue;
			}
		};
		const element = this.output;
		const evalWithContext = getContext(content, block, element);
		let output: any = null;
		try {
			output = await evalWithContext(source);
		} catch (error) {
			output = error;
		}
		this.setState({ output });
	};

	render() {
		const { language } = this.props;
		const { output, status, transforms } = this.state;
		return (
			<div
				ref={ref => {
					this.output = ref;
				}}
				className="output"
				contentEditable={false}
			>
				<ErrorBoundary>
					{language === 'javascript' ? (
						output || null
					) : output ? (
						<Output
							output={output}
							transforms={transforms}
							displayOrder={displayOrder}
						/>
					) : status === 'starting' || status === 'busy' ? (
						<ProgressBar value={status === 'starting' ? 0.3 : 0.7} />
					) : null}
				</ErrorBoundary>
			</div>
		);
	}

	renderContextMenu(event: SyntheticMouseEvent<*>) {
		return (
			<Menu>
				<Menu.Item
					icon="clipboard"
					text="Copy block URI"
					onClick={this.props.copyBlockURI}
				/>
			</Menu>
		);
	}
}

export default ContextMenuTarget(CodeOutput);
