// @flow

// import * as React from 'react';
import {
	richestMimetype,
	transforms,
	displayOrder
} from '@nteract/transforms-full';
import { Button, MenuItem, Classes } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import transform from './transform';
import * as types from '../types';

const React = require('react');
// const { default: transform } = require('./transform');

function getReactComponent(el: Element): React.Component<*> {
	for (const key in el) {
		if (key.startsWith('__reactInternalInstance$')) {
			const fiberNode = el[key];
			return fiberNode && fiberNode.return && fiberNode.return.stateNode;
		}
	}
	return null;
}

function getOutputFromMessages(messages: types.Messages): any {
	const matches = messages.filter((message: types.JupyterMessage) =>
		[
			'execute_result',
			'display_data',
			'update_display_data',
			'error',
			'stream'
		].includes(message.msg_type)
	);
	if (matches) {
		const msg = matches.pop();
		return {
			output_type: msg.msg_type,
			...msg.content
		};
	}
	return null;
}

function getDataFromOutput(output: any) {
	if (output.output_type && output.data) {
		const mimetype = richestMimetype(output.data, displayOrder, transforms);
		return output.data[mimetype];
	}
	if (output.output_type && output.text) {
		return output.text;
	}
	return output;
}

export default function getContext(
	content: {
		get: () => Object
	},
	block: {
		get: (key: string) => Object,
		set: (key: string, data: any) => void
	},
	element?: HTMLDivElement
): (input: string) => Promise<any> {
	// eslint-disable-next-line
	const Table = ({ data }: { data: { [key: string]: any }[] }) => {
		const columns = Object.keys(data[0]);
		const headers = (
			<tr>
				{columns.map((name, index) => (
					<th key={index}>{name}</th>
				))}
			</tr>
		);
		const rows = data.map((item, index) => (
			<tr key={index}>
				{columns.map((column, index) => {
					if (typeof item[column] === 'object') {
						return (
							<td key={index} style={{ width: 200 }}>
								<Tree
									key={index}
									data={item[column]}
									style={{ padding: 10 }}
									expanded={false}
								/>
							</td>
						);
					}
					return <td key={index}>{item[column]}</td>;
				})}
			</tr>
		));
		return (
			<table>
				<thead>{headers}</thead>
				<tbody>{rows}</tbody>
			</table>
		);
	};

	const Tree = ({
		data,
		style,
		expanded = true
	}: {
		data: any,
		style?: {},
		expanded: boolean
	}) => {
		const JSONTree = require('react-json-tree').default;
		const theme = {
			tree: {
				padding: 0,
				margin: 0,
				fontFamily:
					'"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
				fontSize: 14,
				backgroundColor: 'transparent',
				...style
			}
		};
		return (
			<JSONTree
				data={data}
				theme={theme}
				labelRenderer={([label, type]) => {
					let className = 'token keyword';
					if (label === 'root') {
						label = 'Object';
						// className = 'token punctuation';
					}
					// if (type === 'array') {
					// 	className = 'cm-variable-2';
					// }
					// if (type === 'object') {
					// 	className = 'cm-variable-3';
					// }
					return <span className={className}>{`${label}: `}</span>;
				}}
				valueRenderer={raw => {
					let className = 'token string';
					if (typeof raw === 'number') {
						className = 'token number';
					}
					if (raw === 'true' || raw === 'false') {
						className = 'token keyword';
					}
					return <span className={className}>{`${raw}`}</span>;
				}}
				shouldExpandNode={(keyPath, data, level) => expanded}
				hideRoot
			/>
		);
	};

	// eslint-disable-next-line
	const Center = ({ children }: { children: React.Node }) => (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				overflow: 'scroll',
				textAlign: 'center'
			}}
		>
			{children}
		</div>
	);

	// eslint-disable-next-line
	function withToolbar(
		WrappedComponent: React.ComponentType<types.ToolbarOption[]>,
		options: types.ToolbarOption[]
	) {
		function getDefaultValue(option: types.ToolbarOption) {
			switch (option.type) {
				case 'select':
					return option.value || option.options[0] || null;
				case 'input':
					return option.value || '';
				case 'color':
					return option.value || 'black';
				case 'button':
				default:
					return null;
			}
		}

		function getDefaultOptions(options: types.ToolbarOption[]) {
			return options.reduce((result, option) => {
				const key = option.name;
				const value = getDefaultValue(option);
				return { ...result, [key]: value };
			}, {});
		}

		class WithToolbar extends React.Component<*, types.ToolbarOption[]> {
			state = getDefaultOptions(options);

			ref: React.Ref<*>;

			getOptionElement = (option: types.ToolbarOption) => {
				const wrapEventHandler = (
					handler: (ref: React.Ref<*>) => void
				): ((event: Event) => void) => {
					const wrappedHandler = (event: Event): void => {
						handler(this.ref);
					};
					return wrappedHandler;
				};
				switch (option.type) {
					case 'select':
						return (
							<Select
								key={option.name}
								activeItem={null}
								filterable={false}
								items={option.options}
								itemPredicate={(query, item) => {
									return query === ''
										? true
										: item.toLowerCase().includes(query.toLowerCase());
								}}
								itemRenderer={(item, { handleClick, modifiers, query }) => {
									return (
										<MenuItem
											key={item}
											active={modifiers.active}
											disabled={modifiers.disabled}
											// label="Insert"
											onClick={handleClick}
											text={item}
										/>
									);
								}}
								onItemSelect={(item, event) => {
									this.setState({
										[option.name]: item
									});
								}}
								noResults={<MenuItem disabled={true} text="No results" />}
								popoverProps={{ minimal: true }}
								resetOnSelect
							>
								<Button text={this.state[option.name]} rightIcon="caret-down" />
							</Select>
						);
					case 'input':
						return (
							<input
								key={option.name}
								name={option.name}
								type="text"
								className={Classes.INPUT}
								value={this.state[option.name]}
								onChange={this.handleChange}
							/>
						);
					case 'color':
						return (
							<input
								key={option.name}
								name={option.name}
								type="color"
								className={Classes.BUTTON}
								value={this.state[option.name]}
								onChange={this.handleChange}
							/>
						);
					case 'button':
						return (
							<Button
								key={option.name}
								disabled={option.disabled || false}
								onClick={wrapEventHandler(option.onClick)}
							>
								{option.value || option.name}
							</Button>
						);
					default:
						return null;
				}
			};

			handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
				const { name, value } = event.target;
				this.setState({
					[name]: value
				});
			};

			render() {
				return (
					<React.Fragment>
						<div className="actions">
							{options.map(option => this.getOptionElement(option))}
						</div>
						<WrappedComponent
							{...this.props}
							{...this.state}
							ref={ref => {
								this.ref = ref;
							}}
						/>
					</React.Fragment>
				);
			}
		}

		return WithToolbar;
	}

	class ComposureContext {
		queryBlock = (query: string): ?Object => {
			const { values } = require('../containers');
			const blocks: Element[] = Array.from(
				document.querySelectorAll('.ReactBlock')
			);
			if (blocks.length === 0) return new Error('No code blocks in doc');
			const components: React.Component<*>[] = blocks.map(block =>
				getReactComponent(block)
			);
			if (components.length === 0) return new Error('No code blocks in doc');
			const messages: types.MessagesMap = components.reduce(
				(result, component) => {
					if (!component) return result;
					return { ...result, ...component.state.messages };
				},
				{}
			);
			const match: ?types.Messages = values(messages).find(messages =>
				messages.find(
					message =>
						message.msg_type === 'execute_input' &&
						message.content.code.includes(query)
				)
			);
			if (!match) return new Error(`No code blocks containing "${query}"`);
			const output = getOutputFromMessages(match);
			if (!output)
				return new Error(
					`No output associated with code block containing "${query}"`
				);
			return getDataFromOutput(output);
		};

		getOutput = (uri: string): ?Object => {
			const match = uri.match(/\/?(\w+)\/(\w+)\/([\w-]+)/);
			if (!match || match.length < 4) return null;
			// const [raw, user, doc, block] = match;
			const block = match[3];
			const node = document.querySelector(
				`section[data-offset-key="${block}-0-0"] .ReactBlock`
			);
			if (!node) return new Error(`No code block matching URI "${uri}"`);
			const component: ?React.Component<*> = getReactComponent(node);
			if (!component)
				return new Error(
					`No React component associated with code block matching URI "${uri}"`
				);
			// const input = component.props.block.getText();
			const { output } = component.state;
			if (!output)
				return new Error(
					`No output associated with code block matching URI "${uri}"`
				);
			return getDataFromOutput(output);
		};

		getBlock = (uri: string, key?: string): ?Object => {
			const match = uri.match(/\/?(\w+)\/(\w+)\/([\w-]+)/);
			if (!match || match.length < 4) return null;
			// const [raw, user, doc, block] = match;
			const block = match[3];
			const node = document.querySelector(
				`section[data-offset-key="${block}-0-0"] .ReactBlock`
			);
			if (!node) return new Error(`No code block matching URI "${uri}"`);
			const component: ?React.Component<*> = getReactComponent(node);
			if (!component)
				return new Error(
					`No React component associated with code block matching URI "${uri}"`
				);
			// const input = component.props.block.getText();
			// const { output } = component.state;
			const data = component.props.block.getData().toJS();
			if (key) return JSON.parse(data[key]);
			return data;
		};

		getContent = (): ?Object => {
			return content.get();
		};

		import = (name: string): Promise<any> => {
			if (
				name.match(/\/\w+\.js/) ||
				name.match(/\/\/[a-zA-Z0-9_.-]+[.:][a-zA-Z0-9_.-]+\//)
			)
				// eslint-disable-next-line
				return eval(`import('${name}')`);
			// eslint-disable-next-line
			return eval(`import('https://dev.jspm.io/${name}')`);
		};
	}

	// eslint-disable-next-line
	const Composure = new ComposureContext();

	return async function evalWithContext(input: string): Promise<any> {
		// eslint-disable-next-line
		return eval(transform(input));
	};
}
