// @flow

import * as React from 'react';
import AutoReplace from 'slate-auto-replace';
import { when } from '../utils';
import type { Editor, Matches, MarkType, Plugin, Value } from '../../types';

const blockTrigger = (event: SyntheticKeyboardEvent<*>) =>
	event.key &&
	(event.key === 'Enter' || event.key === ' ' || event.key === 'Tab');

const inlineTrigger = (event: SyntheticKeyboardEvent<*>) =>
	event.key.length === 1;

function replaceMarkdownWithInline(
	mark: MarkType
): (
	editor: Editor,
	event: SyntheticKeyboardEvent<*>,
	matches: Matches
) => Plugin {
	return function(
		editor: Editor,
		event: SyntheticKeyboardEvent<*>,
		matches: Matches
	) {
		const [input, before, after] = matches.before;
		const length = input.length - before.length - after.length;
		return editor
			.moveAnchorBackward(length)
			.addMark(mark)
			.moveAnchorForward(length)
			.removeMark(mark)
			.insertText(String.fromCharCode(event.keyCode));
	};
}

export function MarkdownItalic(): Plugin {
	return AutoReplace({
		trigger: inlineTrigger,
		before: /(?:^|\s|\n|[^A-z0-9_*~`])(\*{1}|_{1})(?!\1).*?(\1)($|\s|\n|[^A-z0-9_*~`])/,
		change: replaceMarkdownWithInline('italic')
	});
}

export function MarkdownBold(): Plugin {
	return AutoReplace({
		trigger: inlineTrigger,
		before: /(?:^|\s|\n|[^A-z0-9_*~`])(\*{2}|_{2})(?!\1).*?(\1)($|\s|\n|[^A-z0-9_*~`])/,
		change: replaceMarkdownWithInline('bold')
	});
}

export function MarkdownStrikethrough(): Plugin {
	return AutoReplace({
		trigger: inlineTrigger,
		before: /(?:^|\s|\n|[^A-z0-9_*~`])(~{2})(?!\1).*?(\1)($|\s|\n|[^A-z0-9_*~`])/,
		change: replaceMarkdownWithInline('strikethrough')
	});
}

export function MarkdownInlineCode(): Plugin {
	return AutoReplace({
		trigger: inlineTrigger,
		before: /(?:^|\s|\n|[^A-z0-9_*~`])(`)(?!\1).*?(\1)($|\s|\n|[^A-z0-9_*~`])/,
		change: replaceMarkdownWithInline('code')
	});
}

export function MarkdownHeadings(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^(#{1,5})$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			const [input, before] = matches.before;
			const length = before.length;
			const headings = [
				'title',
				'heading-one',
				'heading-two',
				'heading-three',
				'heading-four',
				'heading-five'
			];
			return editor.setBlocks({ type: headings[length] });
		}
	});
}

export function MarkdownQuote(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^(>)$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			return editor.setBlocks({ type: 'quote' });
		}
	});
}

export function MarkdownCode(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^(`{3}(\w+)?)$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			const [input, before, language] = matches.before;
			return editor.setBlocks({
				type: 'code',
				data: { language },
				isVoid: true
			});
		}
	});
}

export function MarkdownUnorderedList(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^([-*]{1})$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			return editor.setBlocks('list-item').wrapBlock('unordered-list');
		}
	});
}

export function MarkdownOrderedList(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^(1\.)$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			return editor.setBlocks('list-item').wrapBlock('ordered-list');
		}
	});
}

export function MarkdownCheckList(): Plugin {
	return when({
		when: (value: Value) => value.startBlock.type === 'list-item',
		plugin: AutoReplace({
			trigger: blockTrigger,
			before: /(\[(x| )?\])$/i,
			change: (
				editor: Editor,
				event: SyntheticKeyboardEvent<*>,
				matches: Matches
			) => {
				const [input, before, content] = matches.before;
				const checked = content.toLowerCase() === 'x';
				return editor.setBlocks({ type: 'check-list-item', data: { checked } });
			}
		})
	});
}

export function MarkdownDivider(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /^([-*]{3})$/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			return editor.setBlocks({ type: 'divider' }).insertBlock('paragraph');
		}
	});
}

export function MarkdownImage(): Plugin {
	return AutoReplace({
		trigger: blockTrigger,
		before: /(!\[(.*)?\]\((.*)\))/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			const [input, group, text, src] = matches.before;
			return editor.setBlocks({ type: 'image', data: { src } });
		}
	});
}

export function MarkdownLink(): Plugin {
	return AutoReplace({
		trigger: inlineTrigger,
		before: /(?!!)\[(.*)\]\((.*)\)/,
		change: (
			editor: Editor,
			event: SyntheticKeyboardEvent<*>,
			matches: Matches
		) => {
			const [input, text, href] = matches.before;
			const label = text || href;
			// const length = label.length;
			// return editor
			// 	.insertText(label)
			// 	.moveAnchorBackward(length)
			// 	.wrapInline({
			// 		type: 'link',
			// 		data: { href }
			// 	})
			// 	.moveAnchorForward(length)
			// 	.unwrapInline('link')
			// 	.insertText(String.fromCharCode(event.keyCode));
			return editor
				.insertInline({
					type: 'link',
					data: { href }
				})
				.insertText(text || href)
				.splitInline()
				.insertText(' ');
		}
	});
}

const markdownPlugins = [
	MarkdownItalic(),
	MarkdownBold(),
	MarkdownStrikethrough(),
	MarkdownInlineCode(),
	MarkdownHeadings(),
	MarkdownQuote(),
	MarkdownCode(),
	MarkdownCheckList(),
	MarkdownUnorderedList(),
	MarkdownOrderedList(),
	MarkdownDivider(),
	MarkdownImage(),
	MarkdownLink()
];

export default markdownPlugins;
