// @flow

import * as React from 'react';
import { Classes } from '@blueprintjs/core';

export const GoogleIcon = (
	<svg
		width="18px"
		height="18px"
		viewBox="0 0 48 48"
		className="google-icon"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<path
				fill="#EA4335"
				d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
			/>
			<path
				fill="#4285F4"
				d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
			/>
			<path
				fill="#FBBC05"
				d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
			/>
			<path
				fill="#34A853"
				d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
			/>
			<path fill="none" d="M0 0h48v48H0z" />
		</g>
	</svg>
);

export const GoogleProfile = (
	<svg
		width="40px"
		height="40px"
		viewBox="0 0 40 40"
		className="google-profile-icon"
		version="1.1"
		xmlSpace="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<path
			d="M4.02,28.27C2.73,25.8,2,22.98,2,20c0-2.87,0.68-5.59,1.88-8l-1.72-1.04C0.78,13.67,0,16.75,0,20c0,3.31,0.8,6.43,2.23,9.18L4.02,28.27z"
			fill="#F6AD01"
		/>
		<path
			d="M32.15,33.27C28.95,36.21,24.68,38,20,38c-6.95,0-12.98-3.95-15.99-9.73l-1.79,0.91C5.55,35.61,12.26,40,20,40c5.2,0,9.93-1.98,13.48-5.23L32.15,33.27z"
			fill="#249A41"
		/>
		<path
			d="M33.49,34.77C37.49,31.12,40,25.85,40,20c0-5.86-2.52-11.13-6.54-14.79l-1.37,1.46C35.72,9.97,38,14.72,38,20c0,5.25-2.26,9.98-5.85,13.27L33.49,34.77z"
			fill="#3174F1"
		/>
		<path
			d="M20,2c4.65,0,8.89,1.77,12.09,4.67l1.37-1.46C29.91,1.97,25.19,0,20,0l0,0C12.21,0,5.46,4.46,2.16,10.96L3.88,12C6.83,6.08,12.95,2,20,2"
			fill="#E92D18"
		/>
	</svg>
);

export const GoogleDrive = (
	<span className={Classes.ICON}>
		<svg
			width="18px"
			height="18px"
			viewBox="0 0 2048 2048"
			className="google-drive-icon"
			version="1.1"
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g>
				<polygon points="728.353,1245.92 1241.27,372.434 796.716,372.434 796.716,370.101 764.745,370.101 253.987,1261.57 493.463,1645.94 727.067,1248.11 727.067,1245.92 " />
				<polygon points="1789.99,1245.92 1286.48,372.434 1280.95,372.434 1047.43,770.119 1312.5,1245.92 " />
				<polygon points="1770.97,1280.27 747.87,1280.27 514.383,1677.9 1558.22,1677.9 " />
			</g>
			<rect width="2048" height="2048" fill="none" />
		</svg>
	</span>
);
