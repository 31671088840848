'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slateReact = require('slate-react');

var _constants = require('./constants');

var _onArrowLeft = require('./onArrowLeft');

var _onArrowLeft2 = _interopRequireDefault(_onArrowLeft);

var _onArrowRight = require('./onArrowRight');

var _onArrowRight2 = _interopRequireDefault(_onArrowRight);

var _onBackspace = require('./onBackspace');

var _onBackspace2 = _interopRequireDefault(_onBackspace);

var _onDelete = require('./onDelete');

var _onDelete2 = _interopRequireDefault(_onDelete);

var _utils = require('./utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var defaults = {
  allowedTypes: null,
  bannedTypes: [],
  hasStickyBoundaries: true,
  canBeEmpty: true,
  stickOnDelete: true
};
/**
 * Cross Boundaries on Left Arrow, or Right arrow
 * when on the Start or End of an inline boundary
 * can delete all the text inside and still type to add more
 *
 * @param {Object} options
 *   @property {Array} allowedTypes (optional)
 *   @property {Array} bannedTypes (optional)
 *   @property {Boolean} hasStickyBoundaries (optional)
 *   @property {Boolean} canBeEmpty (optional)
 *   @property {Boolean} stickOnDelete (optional)
 * @return {Object} plugin
 */

function StickyInlines(opts) {
  opts = Object.assign({}, defaults, opts);
  var _opts = opts,
      allowedTypes = _opts.allowedTypes,
      bannedTypes = _opts.bannedTypes,
      hasStickyBoundaries = _opts.hasStickyBoundaries,
      canBeEmpty = _opts.canBeEmpty;

  if (allowedTypes && !Array.isArray(allowedTypes)) {
    console.warn('slate-sticky-inline: allowedTypes must be an Array of Strings');
  }

  if (!Array.isArray(bannedTypes)) {
    console.warn('slate-sticky-inlines: bannedTypes must be an Array of Strings');
  }
  /**
   * Keydown entry point.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @param {Next} next
   * @return {Editor}
   */


  function onKeyDown(event, editor, next) {
    // We are working inside a specific inline, and they specifically said they don't want it to be sticky.
    if (editor.value.focusInline && (0, _utils.isInlineBanned)(editor.value.focusInline, opts)) {
      return next();
    } // They are moving the caret around, let's see if we need to interfere.


    switch (event.which) {
      case _constants.ARROW_LEFT:
        return (0, _onArrowLeft2.default)(event, editor, next, opts);

      case _constants.ARROW_RIGHT:
        return (0, _onArrowRight2.default)(event, editor, next, opts);

      case _constants.BACKSPACE:
        return (0, _onBackspace2.default)(event, editor, next, opts);

      case _constants.DELETE:
        return (0, _onDelete2.default)(event, editor, next, opts);

      default:
        return next();
    }
  }
  /**
   * Editor entry point.  Used right now to clean up non-focused empty inline artifacts
   *
   * @param {Editor} editor
   * @return {Editor}
   */


  function onEditor(editor, next) {
    if (!canBeEmpty) {
      return next();
    }

    var toRemove = editor.value.document.getInlines().reduce(function (failures, inline) {
      var hasFocus = editor.value.isFocused && editor.value.selection.hasEdgeIn(inline);
      var onlyHasZeroWidthSpace = inline.text === _constants.ZERO_WIDTH_SPACE;

      if ((0, _utils.isInlineBanned)(inline, opts)) {
        return failures;
      }

      return !hasFocus && onlyHasZeroWidthSpace ? [inline].concat(_toConsumableArray(failures)) : failures;
    }, []);

    if (!toRemove.length) {
      return next();
    }

    toRemove.forEach(function (failure) {
      return editor = editor.removeNodeByKey(failure.key);
    });
    return editor;
  }
  /**
   * Select entry point.  Simply blocks the core onSelect if we
   * set the selection ourselves. It tries to force selections at the end of an
   * inline block to be the next text node over.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @return {Editor}
   */


  function onSelect(event, editor, next) {
    var selection = (0, _slateReact.findRange)(window.getSelection(), editor);
    var selectionIsAtEndOfInline = editor.value.focusInline && selection.focusOffset === editor.value.focusInline.text.length;

    if (editor.value.isCollapsed && selectionIsAtEndOfInline) {
      return editor;
    }

    return next();
  }
  /**
   * Return the plugin.
   *
   * @type {Object}
   */


  return {
    onKeyDown: onKeyDown,
    onEditor: onEditor,
    onSelect: onSelect
  };
}
/**
 * Export.
 *
 * @type {Function}
 */


exports.default = StickyInlines;