// @flow

import * as React from 'react';
import { Button, Menu, Tooltip } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import MonacoEditor from 'react-monaco-editor';
import CodeOutput from './Output';
import { runIcon, bothIcon, inputOnlyIcon, outputOnlyIcon } from './Icons';
import { lightTheme, darkTheme, oneLightTheme, oneDarkTheme } from './themes';
import { copyToClipboard, resolveLanguage } from './utils';
import { languages } from './constants';
import './index.css';
import * as types from '../types';

export default class Code extends React.Component<
	types.CodeProps,
	types.CodeState
> {
	monaco: React.ElementRef<MonacoEditor>;

	editor: types.MonacoEditor;

	output: React.ElementRef<CodeOutput>;

	shouldComponentUpdate(nextProps: types.CodeProps): boolean {
		return (
			nextProps.node !== this.props.node ||
			nextProps.attributes !== this.props.attributes ||
			nextProps.isFocused !== this.props.isFocused ||
			nextProps.isSelected !== this.props.isSelected ||
			nextProps.readOnly !== this.props.readOnly ||
			nextProps.theme !== this.props.theme
		);
	}

	componentDidUpdate(prevProps: types.CodeProps) {
		if (prevProps.isFocused !== this.props.isFocused || this.props.isFocused) {
			if (this.editor) this.editor.focus();
		}
	}

	editorWillMount = (monaco: types.Monaco) => {
		this.monaco = monaco;
		this.monaco.editor.defineTheme('light', lightTheme);
		this.monaco.editor.defineTheme('dark', darkTheme);
		this.monaco.editor.defineTheme('one-light', oneLightTheme);
		this.monaco.editor.defineTheme('one-dark', oneDarkTheme);
		this.monaco.languages.registerDocumentFormattingEditProvider('javascript', {
			async provideDocumentFormattingEdits(model, options, token) {
				const prettier = await import('prettier/standalone');
				const babylon = await import('prettier/parser-babylon');
				const text = prettier.format(model.getValue(), {
					parser: 'babylon',
					plugins: [babylon],
					singleQuote: true
				});
				return [
					{
						range: model.getFullModelRange(),
						text
					}
				];
			}
		});
	};

	editorDidMount = (editor: types.MonacoEditor, monaco: types.Monaco) => {
		this.editor = editor;
		this.editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
			this.executeOrEval();
		});
		this.editor.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Enter, () => {
			this.props.editor
				.splitBlock()
				.setBlocks('paragraph')
				.focus();
		});
		this.editor.onKeyDown(event => {
			if (
				event.keyCode === monaco.KeyCode.Backspace &&
				this.getSource() === ''
			) {
				this.props.editor.resetBlock();
			}
			if (
				event.keyCode === monaco.KeyCode.UpArrow &&
				this.editor.cursor.getPosition().lineNumber === 1
			) {
				this.props.editor
					.moveToEndOfPreviousBlock()
					.moveToEndOfNextBlock()
					.focus();
			}
			if (
				event.keyCode === monaco.KeyCode.DownArrow &&
				this.editor.cursor.getPosition().lineNumber ===
					this.editor.getModel().getLineCount()
			) {
				this.props.editor
					.moveToEndOfNextBlock()
					.moveToEndOfPreviousBlock()
					.focus();
			}
		});
		// this.editor.onDidFocusEditorWidget(() => {
		// 	this.props.editor.moveTo(this.props.node.key);
		// });
		if (this.props.isFocused && this.editor) this.editor.focus();
	};

	changeLanguage = (
		item: types.Language,
		event: SyntheticInputEvent<HTMLSelectElement>
	): void => {
		this.setBlockData({ language: item.name });
	};

	copyBlockURI = (): void => {
		const doc = window.location.pathname.substring(1);
		const block = this.props.node.key;
		copyToClipboard(`${doc}/${block}`);
	};

	toggleDisplay = (event: SyntheticEvent<HTMLButtonElement>): void => {
		const mode = this.getBlockData('mode') || 'both';
		if (mode === 'both') this.setBlockData({ mode: 'output' });
		if (mode === 'output') this.setBlockData({ mode: 'input' });
		if (mode === 'input') this.setBlockData({ mode: 'both' });
	};

	getValue = () => {
		return this.props.editor.value.toJS();
	};

	getBlockData = (key?: string): any => {
		if (key) return this.props.node.data.get(key);
		return this.props.node.data.toJS();
	};

	setBlockData = (data: types.IndexedObject): void => {
		this.props.editor.setBlocks({ data: this.props.node.data.merge(data) });
	};

	getSource = (): string => {
		return this.getBlockData('source') || this.props.node.text || '';
	};

	getLanguage = (): string => {
		return resolveLanguage(this.getBlockData('language') || 'javascript');
	};

	executeOrEval = (): void => {
		const language = this.getLanguage();
		if (language === 'javascript') {
			this.output.eval();
		} else {
			this.output.execute();
		}
	};

	render() {
		const {
			attributes,
			// children,
			// editor,
			isFocused,
			isSelected,
			// node,
			readOnly,
			theme
		} = this.props;
		const language = this.getLanguage();
		const mode = this.getBlockData('mode') || 'both';
		const source = this.getSource();
		const editorRoot = document.querySelector('div[data-slate-editor]');
		const lineCount = source.split('\n').length;
		const isScrollable = lineCount * 21 > 600;
		const width = editorRoot ? editorRoot.offsetWidth : 640;
		const height = isScrollable ? 600 : lineCount * 21;
		// const lineNumbers = lineCount > 1 ? 'on' : 'off';
		return (
			<div
				{...attributes}
				className={
					isFocused || isSelected
						? 'ReactBlock MonacoBlock focused'
						: 'ReactBlock MonacoBlock unfocused'
				}
			>
				<div className="actions">
					<Tooltip content="Run code block" disabled={readOnly}>
						<Button
							disabled={readOnly}
							onClick={this.executeOrEval}
							icon={runIcon}
						/>
					</Tooltip>
					<Tooltip
						content="Toggle visibility of code block's input and output"
						disabled={readOnly}
						hoverOpenDelay={1000}
					>
						<Button
							disabled={readOnly}
							onClick={this.toggleDisplay}
							icon={
								mode === 'both'
									? bothIcon
									: mode === 'output'
									? outputOnlyIcon
									: inputOnlyIcon
							}
						/>
					</Tooltip>
					<Select
						disabled={readOnly}
						activeItem={languages.find(
							_language => _language.name === language
						)}
						// filterable={false}
						items={languages}
						itemPredicate={(query, item) => {
							return query === ''
								? true
								: item.name.toLowerCase().includes(query.toLowerCase());
						}}
						itemRenderer={(item, { handleClick, modifiers, query }) => {
							return (
								<Menu.Item
									active={modifiers.active}
									disabled={modifiers.disabled}
									key={item.name}
									onClick={handleClick}
									text={item.displayName}
								/>
							);
						}}
						onItemSelect={this.changeLanguage}
						noResults={<Menu.Item disabled={true} text="No results" />}
						popoverProps={{ minimal: true }}
						resetOnSelect
					>
						<Tooltip
							content="Change language of code block"
							disabled={readOnly}
						>
							<Button
								disabled={readOnly}
								text={
									languages.find(_language => _language.name === language)
										.displayName
								}
								rightIcon="caret-down"
							/>
						</Tooltip>
					</Select>
				</div>
				{mode === 'both' || mode === 'input' ? (
					<div
						className="monaco-padding"
						onClick={event => {
							event.stopPropagation();
							if (this.editor) this.editor.focus();
						}}
					>
						<MonacoEditor
							width={width}
							height={height}
							language={language}
							theme={theme}
							value={source}
							options={{
								autoIndent: true,
								// automaticLayout: true,
								contextmenu: false,
								// cursorStyle: 'block',
								cursorBlinking: 'smooth',
								cursorWidth: 1,
								fontFamily:
									'"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
								fontSize: 14,
								formatOnPaste: true,
								formatOnType: true,
								// glyphMargin: true,
								lineNumbers: true,
								minimap: {
									enabled: isScrollable,
									maxColumn: 30,
									renderCharacters: false,
									showSlider: 'always'
								},
								readOnly,
								renderLineHighlight: 'none',
								scrollbar: {
									horizontal: 'hidden',
									useShadows: false,
									vertical: 'hidden'
								},
								scrollBeyondLastLine: isScrollable,
								wordWrap: 'bounded',
								wordWrapColumn: 80
							}}
							onChange={value => {
								this.setBlockData({ source: value });
							}}
							editorWillMount={this.editorWillMount}
							editorDidMount={this.editorDidMount}
						/>
					</div>
				) : null}
				{mode === 'both' || mode === 'output' ? (
					<CodeOutput
						ref={ref => {
							this.output = ref;
						}}
						source={source}
						language={language}
						getValue={this.getValue}
						getBlockData={this.getBlockData}
						setBlockData={this.setBlockData}
						copyBlockURI={this.copyBlockURI}
					/>
				) : null}
			</div>
		);
	}
}
