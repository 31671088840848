// @flow

import * as React from 'react';
import { Editor, Value } from '../types';

export const commands = {
	commands: {
		// updateBlockData(editor: types.Editor, data: types.IndexedObject) {
		// 	editor.setBlocks({ data: { ...editor.editor.value.startBlock.data.toJS(), ...data } });
		// }
		resetBlock(editor: Editor) {
			editor.setBlocks('paragraph');
		}
	}
};

export const queries = {
	queries: {
		getTitle({ value }: { value: Value }) {
			const title = value.document.nodes.find(node => node.type === 'title');
			if (title) return title.text;
			return 'Untitled';
		}
	}
};
