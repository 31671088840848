// @flow

import * as React from 'react';
import { Button, Intent, Popover, Menu, Tag, Classes } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import moment from 'moment';
import { GoogleDrive, GoogleProfile, GoogleIcon } from './Icons';
import './Toolbar.css';
import * as types from './types';

moment.relativeTimeThreshold('ss', 2);

export const UserMenu = ({
	user,
	handleLogin,
	handleLogout,
	loading
}: types.UserMenuProps) => (
	<Popover
		target={
			<div className={loading ? `profile ${Classes.SKELETON}` : 'profile'}>
				{GoogleProfile}
				{user.id ? (
					<img className="picture" src={user.picture} alt="user" />
				) : (
					<div className="picture logged-out" />
				)}
			</div>
		}
		content={
			user.id ? (
				<Menu>
					<div className="logged-in">
						<img className="picture" src={user.picture} alt="user" />
						<div className="info">
							<div className={Classes.TEXT_LARGE}>{user.name}</div>
							<div className={Classes.TEXT_MUTED}>{user.email}</div>
						</div>
					</div>
					<Menu.Divider />
					<Menu.Item text="Logout" icon={GoogleIcon} onClick={handleLogout} />
				</Menu>
			) : (
				<Menu>
					<Menu.Item text="Login" icon={GoogleIcon} onClick={handleLogin} />
				</Menu>
			)
		}
		position="bottom-right"
		className="user"
		disabled={loading}
	/>
);

export const ShareMenu = ({
	user,
	shareDoc,
	locateDoc,
	loading
}: types.ShareMenuProps) => {
	if (user.id)
		return (
			<Popover
				target={<Button icon="more" minimal large />}
				content={
					<Menu>
						<Menu.Item text="Share" icon="new-person" onClick={shareDoc} />
						<Menu.Item
							text="Locate in Google Drive"
							icon={GoogleDrive}
							onClick={locateDoc}
						/>
					</Menu>
				}
				position="bottom-right"
				className="share"
				disabled={loading}
			/>
		);
	return null;
};

export const RevisionsMenu = ({
	revisions,
	doc,
	fetchRevision
}: types.RevisionsMenuProps) => {
	const { headRevisionId } = doc;
	if (revisions.length > 0)
		return (
			<Select
				className="revisions"
				activeItem={
					revisions.find(revision => revision.id === headRevisionId) ||
					revisions[0]
				}
				filterable={false}
				items={revisions}
				itemRenderer={(item, { handleClick, modifiers, query }) => {
					return (
						<Menu.Item
							active={modifiers.active}
							disabled={modifiers.disabled}
							key={item.id}
							onClick={handleClick}
							text={moment(item.modifiedTime).calendar()}
							labelElement={
								item.lastModifyingUser.me ? (
									<Tag intent={Intent.PRIMARY}>Me</Tag>
								) : (
									<Tag minimal>
										{item.lastModifyingUser.displayName
											.match(/\b\w/g)
											.join(' ')}
									</Tag>
								)
							}
						/>
					);
				}}
				onItemSelect={item => {
					fetchRevision(item);
				}}
				// noResults={<Menu.Item disabled={true} text="No results" />}
				popoverProps={{ minimal: true }}
				resetOnSelect
			>
				<Button
					text={moment(
						(
							revisions.find(revision => revision.id === headRevisionId) ||
							revisions[0]
						).modifiedTime
					).calendar()}
					rightIcon="caret-down"
					minimal
				/>
			</Select>
		);
	return null;
};
