// @flow

import * as React from 'react';
import { Classes } from '@blueprintjs/core';

export const runIcon = (
	<span className={Classes.ICON}>
		<svg width="16px" height="16px" viewBox="0 0 160 160">
			<polygon points="150 80 10 160 10 0" />
		</svg>
	</span>
);

export const bothIcon = (
	<span className={Classes.ICON}>
		<svg width="16px" height="16px" viewBox="0 0 160 160">
			<path
				d="M160,72 L0,72 L0,0 L160,0 L160,72 Z M160,88 L160,160 L0,160 L0,88 L160,88 Z"
				fillRule="evenodd"
			/>
		</svg>
	</span>
);

export const outputOnlyIcon = (
	<span className={Classes.ICON}>
		<svg width="16px" height="16px" viewBox="0 0 160 160">
			<path
				d="M0,0 L160,0 L160,160 L0,160 L0,0 Z M4,4 L4,78 L156,78 L156,4 L4,4 Z"
				fillRule="evenodd"
			/>
		</svg>
	</span>
);

export const inputOnlyIcon = (
	<span className={Classes.ICON}>
		<svg width="16px" height="16px" viewBox="0 0 160 160">
			<path
				d="M0,0 L160,0 L160,160 L0,160 L0,0 Z M4,82 L4,156 L156,156 L156,82 L4,82 Z"
				fillRule="evenodd"
			/>
		</svg>
	</span>
);
