// @flow

import { Block } from 'slate';
import * as types from './types';

// const schema = {
// 	document: {
// 		nodes: [
// 			// {
// 			// 	types: [
// 			// 		'paragraph',
// 			// 		'title',
// 			// 		'heading-one',
// 			// 		'heading-two',
// 			// 		'heading-three',
// 			// 		'heading-four',
// 			// 		'heading-five',
// 			// 		'quote',
// 			// 		'code',
// 			// 		'unordered-list',
// 			// 		'ordered-list',
// 			// 		'list-item',
// 			// 		'todo-list',
// 			// 		'divider',
// 			// 		'image',
// 			// 		'table'
// 			// 	],
// 			// 	min: 1
// 			// },
// 			{ match: { type: 'title' }, min: 1, max: 1 },
// 			{ match: { type: 'paragraph' }, min: 1 }
// 		],
// 		normalize: (change: Change, { code, node, child, index }: Block) => {
// 			switch (code) {
// 				case 'child_type_invalid': {
// 					const type = index === 0 ? 'title' : 'paragraph';
// 					return change.setNodeByKey(child.key, type);
// 				}
// 				case 'child_required': {
// 					const block = Block.create(index === 0 ? 'title' : 'paragraph');
// 					return change.insertNodeByKey(node.key, index, block);
// 				}
// 			}
// 		}
// 	}
// 	// blocks: {
// 	// 	paragraph: { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	title: { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'heading-one': { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'heading-two': { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'heading-three': { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'heading-four': { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'heading-five': { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	quote: { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	code: { nodes: [{ objects: ['text'] }], marks: [''] },
// 	// 	'unordered-list': { nodes: [{ types: ['list-item'] }] },
// 	// 	'ordered-list': { nodes: [{ types: ['list-item'] }] },
// 	// 	'todo-list': { nodes: [{ types: ['list-item'] }] },
// 	// 	divider: {
// 	// 		isVoid: true
// 	// 	},
// 	// 	image: {
// 	// 		isVoid: true
// 	// 	},
// 	// 	table: {
// 	// 		nodes: [{ types: ['table-row', 'table-head', 'table-cell'] }]
// 	// 	}
// 	// }
// };

const schema = {
	document: {
		first: { type: 'title' },
		last: { type: 'paragraph' },
		normalize: (editor: types.Editor, { code, node, child }: types.Block) => {
			switch (code) {
				case 'first_child_type_invalid':
					return editor.setNodeByKey(child.key, 'title');
				case 'last_child_type_invalid':
					return editor.insertNodeByKey(
						node.key,
						node.nodes.size,
						Block.create('paragraph')
					);
				default:
					return;
			}
		}
	},
	blocks: {
		code: {
			isVoid: true
		},
		image: {
			isVoid: true
		},
		divider: {
			isVoid: true
		}
	}
};

export default schema;
