// @flow

import React from 'react';
import { Card } from '@blueprintjs/core';
import './Errors.css';

export const GenericError = ({ error }: { error: Error }) => (
	<div className="content error">
		<Card className="markdown center">
			<h1>
				<span className="icon" role="img" aria-label="error">
					⚠️
				</span>
				{error.name}
			</h1>
			<p>{error.message}</p>
			<pre className="left">{error.stack}</pre>
		</Card>
	</div>
);
