// @flow

import PasteLink from './paste-link';
// import PasteMarkdown from './paste-markdown';
import InsertImages from 'slate-drop-or-paste-images';
import type { Editor } from '../../types';

const pastePlugins = [
	// PasteHTML(),
	// PasteMarkdown(),
	InsertImages({
		extensions: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
		insertImage: (editor: Editor, src: string) => {
			return editor.setBlocks({
				type: 'image',
				data: { src }
			});
		}
	}),
	PasteLink()
];

export default pastePlugins;
