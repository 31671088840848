'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = onDelete;

var _constants = require('./constants');

var _utils = require('./utils');
/**
 * Sticky Delete Link logic
 *
 * @param {Event} event
 * @param {Editor} editor
 * @param {Next} next
 * @param {Objects} opts
 * @return {Any}
 */


function onDelete(event, editor, next, opts) {
  var canBeEmpty = opts.canBeEmpty,
      stickOnDelete = opts.stickOnDelete;
  if (editor.value.isExpanded) return next(); // Logic for deleting "into" a sticky inline

  var isAtEndOfCurrentTextNode = !editor.value.focusInline && editor.value.selection.focusOffset === editor.value.focusText.text.length;

  if (isAtEndOfCurrentTextNode && stickOnDelete) {
    var textNodeIndex = editor.value.focusBlock.nodes.findIndex(function (node) {
      return node.key === editor.value.focusText.key;
    }) + 1;
    var upcomingNode = editor.value.focusBlock.nodes.get(textNodeIndex);
    if ((0, _utils.isInlineBanned)(upcomingNode, opts)) return next();
    event.preventDefault();
    return editor.moveToStartOfNode(upcomingNode).deleteForward();
  } // Logic for deleting inside the sticky inline


  if (!editor.value.focusInline || !canBeEmpty) return next();

  if (editor.value.focusInline.text.length === 1 && editor.value.focusInline.text === _constants.ZERO_WIDTH_SPACE) {
    return next();
  }

  if (editor.value.focusInline.text.length !== 1) return next();
  event.preventDefault();
  return editor.insertText(_constants.ZERO_WIDTH_SPACE).move(-1).deleteBackward().move(1);
}