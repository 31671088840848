// @flow

import * as React from 'react';
import { GenericError } from './Errors';
import type { ChildrenProps, ErrorBoundaryState } from './types';

export default class ErrorBoundary extends React.Component<
	ChildrenProps,
	ErrorBoundaryState
> {
	state = { error: null };

	componentDidCatch(error: Error, info: { componentStack: string }) {
		const { name } = error;
		const message = error.message.replace(/\n.*/g, '');
		const stack = info.componentStack.replace(
			/in (.+) \(at (.+)\)/gm,
			'at $1 ($2)'
		);
		this.setState({ error: { name, message, stack } });
		window.gtag('event', 'exception', {
			description: `${name}: ${message}\n${stack}`,
			fatal: false
		});
	}

	render() {
		if (this.state.error) {
			return <GenericError error={this.state.error} />;
		}
		return this.props.children;
	}
}
