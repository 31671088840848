// @flow

import type { Editor, Value, Plugin, Next } from '../types';

export function when({
	when,
	plugin
}: {
	when: (value: Value) => boolean,
	plugin: Plugin
}): Plugin {
	const PLUGIN_HANDLERS: $Keys<Plugin>[] = [
		'onBeforeInput',
		'onBlur',
		'onCopy',
		'onCut',
		'onDrop',
		'onInput',
		'onKeyDown',
		'onKeyUp',
		'onPaste',
		'onSelect'
	];
	return PLUGIN_HANDLERS.reduce((result, handler) => {
		if (plugin[handler]) {
			return {
				...result,
				[handler]: (event: SyntheticEvent<*>, editor: Editor, next: Next) => {
					if (!when(editor.value)) return next();
					return plugin[handler](event, editor, next);
				}
			};
		}
		return result;
	}, {});
}

export function excludeCodeBlocks(plugin: Plugin | Plugin[]): Plugin {
	if (Array.isArray(plugin)) {
		return plugin.map(plugin => excludeCodeBlocks(plugin));
	}
	return when({
		when: (value: Value) => value.blocks.every(block => block.type !== 'code'),
		plugin
	});
}

export function toTitleCase(text: string): string {
	return text.replace(/\w/, x => x.toUpperCase());
}

export function isUrl(text: string): boolean {
	const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
	const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
	const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
	if (typeof text !== 'string') return false;
	const match = text.match(protocolAndDomainRE);
	if (!match) return false;
	const everythingAfterProtocol = match[1];
	if (!everythingAfterProtocol) return false;
	if (
		localhostDomainRE.test(everythingAfterProtocol) ||
		nonLocalhostDomainRE.test(everythingAfterProtocol)
	) {
		return true;
	}
	return false;
}
