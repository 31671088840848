// @flow

import * as React from 'react';
import type { Editor, Next } from '../../types';

export function HandleNewCheckListItem(
	{ type }: { type: string } = { type: 'paragraph' }
) {
	return {
		onKeyDown: (
			event: SyntheticKeyboardEvent<*>,
			editor: Editor,
			next: Next
		) => {
			if (
				event.key === 'Enter' &&
				editor.value.startBlock.type === 'check-list-item'
			) {
				return editor.splitBlock().setBlocks({ data: { checked: false } });
			}
			return next();
		}
	};
}

export class CheckListItem extends React.Component {
	onEditor = (event: SyntheticEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		this.props.editor.editor((editor: Editor) =>
			editor.setNodeByKey(this.props.node.key, { data: { checked } })
		);
	};

	render() {
		const { attributes, children, node, readOnly } = this.props;
		const checked = node.data.get('checked');
		return (
			<li {...attributes} className="task-list-item" contentEditable={false}>
				<input
					type="checkbox"
					className="task-list-item-checkbox"
					checked={checked}
					onEditor={this.onEditor}
				/>
				<span
					checked={checked}
					contentEditable={!readOnly}
					suppressContentEditableWarning
				>
					{children}
				</span>
			</li>
		);
	}
}
