// @flow

import * as React from 'react';
import type { Editor, Next } from '../../types';

export default function ResetBlock(
	{ type }: { type: string } = { type: 'paragraph' }
) {
	return {
		onKeyDown: (
			event: SyntheticKeyboardEvent<*>,
			editor: Editor,
			next: Next
		) => {
			if (
				event.key === 'Backspace' &&
				editor.value.selection.start.offset === 0 &&
				editor.value.startBlock.type !== type
			) {
				event.preventDefault();
				if (editor.value.startBlock.type.endsWith('list-item')) {
					editor.unwrapBlock('ordered-list');
					editor.unwrapBlock('unordered-list');
				}
				editor.setBlocks(type);
			} else {
				return next();
			}
		}
	};
}
