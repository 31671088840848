// @flow

import * as React from 'react';
import type { Editor, Next } from '../../types';

export default function HardBreak(
	{ shift }: { shift: boolean } = { shift: false }
) {
	return {
		onKeyDown: (
			event: SyntheticKeyboardEvent<*>,
			editor: Editor,
			next: Next
		) => {
			if (event.key === 'Enter') {
				if ((shift && event.shiftKey) || (!shift && !event.shiftKey)) {
					return editor.splitBlock().setBlocks('paragraph');
				}
			}
			return next();
		}
	};
}
