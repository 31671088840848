// @flow
import * as React from 'react';
import * as Slate from 'slate';
import * as rxjs from 'rxjs';
import * as Immutable from 'immutable';
import * as monaco from 'monaco-editor';

export type Exact<T> = T & $Shape<T>;

export type Primitive = string | number;

export type IndexedObject = { [key: string]: any };

/* gapi profile object */
// interface GoogleUser {
// 	email: string;
// 	email_verified: boolean;
// 	family_name: string;
// 	gender: 'male' | 'female';
// 	given_name: string;
// 	locale: string;
// 	name: string;
// 	picture: string;
// 	profile: string;
// 	sub: string;
// }

/* react-google-login profile object */
// interface GoogleUser {
// 	email: string;
// 	family_name: string;
// 	given_name: string;
// 	googleId: string;
// 	imageUrl: string;
// 	name: string;
// }

/* Google API */
export type GoogleUser = {
	id: string,
	name: string,
	email: string,
	picture: string
};

export type GoogleAuthResponse = {
	googleId: string,
	tokenId: string,
	accessToken: string,
	tokenObj: Object,
	profileObj: GoogleUser
};

export type DrivePermission = {
	deleted: boolean,
	displayName: string,
	emailAddress: string,
	id: string,
	kind: string,
	photoLink: string,
	role: string,
	type: string
};

export type DriveCapabilties = {
	canAddChildren: boolean,
	canChangeCopyRequiresWriterPermission: boolean,
	canChangeViewersCanCopyContent: boolean,
	canComment: boolean,
	canCopy: boolean,
	canDelete: boolean,
	canDeleteChildren: boolean,
	canDownload: boolean,
	canEdit: boolean,
	canListChildren: boolean,
	canMoveChildrenOutOfTeamDrive: boolean,
	canMoveChildrenWithinTeamDrive: boolean,
	canMoveItemIntoTeamDrive: boolean,
	canMoveItemOutOfTeamDrive: boolean,
	canMoveItemWithinTeamDrive: boolean,
	canMoveTeamDriveItem: boolean,
	canReadRevisions: boolean,
	canReadTeamDrive: boolean,
	canRemoveChildren: boolean,
	canRename: boolean,
	canShare: boolean,
	canTrash: boolean,
	canTrashChildren: boolean,
	canUntrash: boolean
};

export type DriveFileOwner = {
	displayName: string,
	emailAddress?: string,
	kind?: string,
	permissionId?: string,
	photoLink?: string,
	me?: boolean
};

export type DriveFile = {
	id: string,
	name: string,
	starred: boolean,
	parents: string[],
	version: number,
	createdTime: Date,
	modifiedTime: Date,
	properties: IndexedObject,
	appProperties: IndexedObject,
	owners: DriveFileOwner[],
	shared: boolean,
	md5Checksum: string,
	size: number,
	headRevisionId: string,
	modifiedByMeTime: Date,
	lastModifyingUser: DriveFileOwner,
	permissions: DrivePermission[],
	capabilities: DriveCapabilties,
	webContentLink: string,
	webViewLink: string
};

export type DriveRevision = {
	kind?: 'drive#revision',
	id: string,
	// mimeType: string;
	modifiedTime: string,
	// keepForever: boolean;
	// published: boolean;
	// publishAuto: boolean;
	// publishedOutsideDomain: boolean;
	lastModifyingUser: DriveFileOwner,
	originalFilename: string,
	// md5Checksum: string;
	size: string
};

export type DriveState = {
	folderId?: string,
	ids?: string[],
	userId?: string,
	action: 'open' | 'create'
};

export type DriveParams = {
	[key: string]: string,
	state: DriveState,
	code?: string
};

export type DriveRequestErrorResult = {
	domain: string,
	location: string,
	locationType: string,
	message: string,
	reason: string
};

export type DriveRequestError = {
	body: string,
	headers: IndexedObject,
	result: {
		error: {
			code: number,
			errors: DriveRequestErrorResult[],
			message: string
		}
	},
	status: number,
	statusText: ?string
};

/* Slate API */

export type Editor = Slate.Editor;

export type Change = Slate.Change;

export type Value = Slate.Value;

export type Block = Slate.Block;

export type Range = Slate.Range;

export type Inline = Slate.Inline;

export type Selection = Slate.Value;

export type MarkType =
	| 'italic'
	| 'bold'
	| 'strikethrough'
	| 'underline'
	| 'code';

export type NodeType =
	| 'paragraph'
	| 'title'
	| 'heading-one'
	| 'heading-one'
	| 'heading-two'
	| 'heading-three'
	| 'heading-four'
	| 'heading-five'
	| 'quote'
	| 'code'
	| 'unordered-list'
	| 'ordered-list'
	| 'list-item'
	| 'footnote'
	| 'divider'
	| 'link'
	| 'image'
	| 'table';

export type Mark = Slate.Mark & { type: MarkType };

export type Node = Slate.Node & { type: NodeType };

export type Operation = Slate.Operation;

export type Plugin = {
	normalizeNode?: Function,
	onChange?: Function,
	onCommand?: Function,
	onConstruct?: Function,
	onQuery?: Function,
	validateNode?: Function,
	decorateNode?: Function,
	onBeforeInput?: Function,
	onBlur?: Function,
	onCopy?: Function,
	onCut?: Function,
	onDrop?: Function,
	onFocus?: Function,
	onInput?: Function,
	onKeyDown?: Function,
	onKeyUp?: Function,
	onPaste?: Function,
	onSelect?: Function,
	renderEditor?: Function,
	renderMark?: Function,
	renderNode?: Function,
	renderPlaceholder?: Function,
	shouldNodeComponentUpdate?: Function
};

export type Next = () => Plugin;

export type Matches = {
	before: RegExp$matchResult,
	after: RegExp$matchResult
};

export type RenderMarkProps = {
	attributes: IndexedObject,
	children: React.Node,
	editor: Editor,
	mark: Mark,
	marks: Mark[],
	node: Node,
	offset: number,
	text: string
};

export type RenderNodeProps = {
	attributes: IndexedObject,
	children: React.Node,
	editor: Editor,
	isFocused: boolean,
	isSelected: boolean,
	// key: string,
	node: Node,
	parent: Node,
	readOnly: boolean
};

export type User = GoogleUser;

export type DocContent = Slate.Value;

export type Doc = DriveFile & { content: DocContent };

export type DocsMap = {
	[string]: Doc
};

export type Unsubscribe = () => void;

export type ToolbarOption = {
	name: string,
	type: string,
	options?: Primitive[],
	value: Primitive,
	onClick?: (ref: React.Ref<any>) => void
};

export type MenuItem = {
	name: string,
	label: string,
	onSelect?: () => void,
	[key: string]: any
};

export type AWSIoTDevice = {
	on: (event: string, callback: any) => void,
	subscribe: (topic: string) => void,
	publish: (
		topic: string,
		message: string,
		options: IndexedObject,
		callback?: (error: Error) => void
	) => void,
	end: () => void
};

export type LanguageName =
	| 'javascript'
	| 'nodejs'
	| 'python'
	| 'r'
	| 'julia'
	| 'bash';

export type LanguageDisplayName =
	| 'Javascript'
	| 'Node.js'
	| 'Python'
	| 'R'
	| 'Julia'
	| 'Bash';

export type Language = {
	name: LanguageName,
	displayName: LanguageDisplayName
};

export type OutputMode = 'both' | 'input' | 'output';

export type Widget = {
	name: string,
	label: string,
	type: string,
	data: {
		source: string,
		language: LanguageName,
		mode: OutputMode
	}
};

export type Subscription = rxjs.Subscription;

export type Observable = rxjs.Observable<any>;

export type JupyterMessageHeader<MT: string> = {
	msg_id: string,
	username: string,
	date: string, // ISO 8601 timestamp
	msg_type: MT, // this could be an enum
	version: string // this could be an enum
};

export type JupyterMessage<MT, C> = {
	header: JupyterMessageHeader<MT>,
	parent_header: JupyterMessageHeader<*> | {},
	metadata: Object,
	content: C,
	buffers?: Array<any> | null
};

export type ExecuteMessageContent = {
	code: string,
	silent: boolean,
	store_history: boolean,
	user_expressions: Object,
	allow_stdin: boolean,
	stop_on_error: boolean
};

export type ExecuteRequest = JupyterMessage<
	'execute_request',
	ExecuteMessageContent
>;

export type Channels = rxjs$Subject<JupyterMessage<*, *>>;

export type Message = JupyterMessage<any>;

export type MessagesMap = { [parent: string]: Message[] };

export type BinderOptions = {
	repo: string,
	ref?: string,
	binderURL?: string
};

export type BinderHost = {
	endpoint: string,
	token: string,
	crossDomain: boolean
};

export type CachedHost = {
	active: boolean,
	config: BinderHost
};

export type KernelOptions = {
	kernelName: string,
	host: BinderHost
};

export type KernelState = {
	channels: Channels,
	connections: number,
	execution_state: string,
	id: string,
	last_activity: Date,
	name: string
};

export type Kernel = KernelState;

export type KernelMap = {
	[language: LanguageName]: Promise<?Kernel>
};

export type KernelStatus =
	| 'starting'
	| 'busy'
	| 'idle'
	| 'ok'
	| 'error'
	| 'abort';

	export type Monaco = monaco.monaco;

export type MonacoEditor = monaco.editor;

// export type HistoryAction = 'PUSH' | 'REPLACE' | 'POP';

// export type HistoryLocationState = {
// 	[string]: boolean
// };

// export type HistoryLocation = {
// 	key: string,
// 	pathname: string,
// 	search: string,
// 	hash: string,
// 	state: HistoryLocationState
// };

// export type RouteProps = {
// 	match: {
// 		params: { [string]: string },
// 		isExact: boolean,
// 		path: string,
// 		url: string
// 	},
// 	location: Location,
// 	history: {
// 		length: number,
// 		action: 'PUSH' | 'REPLACE' | 'POP',
// 		location: HistoryLocation,
// 		push: (path: string, state?: HistoryLocationState) => void,
// 		replace: (path: string, state?: HistoryLocationState) => void,
// 		go: (n: number) => void,
// 		goBack: () => void,
// 		goForward: () => void,
// 		block: (
// 			prompt:
// 				| string
// 				| ((location: HistoryLocation, action: HistoryAction) => string)
// 		) => void
// 	}
// };

export type UserProp = {
	user: User
};

export type DocProp = {
	doc: Doc
};

export type DocsProp = {
	docs: Doc[]
};

export type HandleLogin = () => Promise<void>;

export type HandleLogout = () => void;

export type Theme = 'light' | 'dark';

export type ShareDoc = () => void;

export type LocateDoc = () => void;

export type FetchRevision = (revision: DriveRevision) => Promise<void>;

export type Transform = React.Node;

export type RevisionsProp = {
	revisions: DriveRevision[]
};

export type LoginProp = {
	handleLogin: HandleLogin
};

export type LogoutProp = {
	handleLogout: HandleLogout
};

export type LoadingProp = {
	loading: boolean
};

export type ReadOnlyProp = {
	readOnly: boolean
};

export type ThemeProp = { theme: Theme };

export type UpdateDocProp = {
	updateDoc: (doc: Doc, text: string) => void
};

export type PublishChangeProp = {
	publishChange: (operations: Operation[], time: Date) => void
};

export type ShareDocProp = {
	shareDoc: () => void
};

export type LocateDocProp = {
	locateDoc: () => void
};

export type FetchRevisionProp = {
	fetchRevision: FetchRevision
};

export type ErrorProp = {
	error: DriveRequestError | null
};

export type withDocProps = UserProp & DocsProp & LoginProp & LogoutProp;

export type withDocState = { doc: Doc | {} } & {
	revisions: DriveRevision[] | []
} & LoadingProp &
	ErrorProp;

export type withAuthProps = {};

export type withAuthState = { user: User | {} } & DocsProp &
	LoadingProp &
	ErrorProp;

export type AppMenuProps = {
	items: MenuItem[]
};

export type AppMenuState = {};

export type UserMenuProps = UserProp & LoginProp & LogoutProp & LoadingProp;

export type ShareMenuProps = UserProp & ShareDocProp & LocateDoc & LoadingProp;

export type RevisionsMenuProps = RevisionsProp & DocProp & FetchRevisionProp;

export type MenuWithHotKeyProps = {
	hotkey: string,
	name: string,
	items: MenuItem[]
};

export type MenuWithHotKeyState = {
	open: boolean
};

export type DocProps = DocProp &
	DocsProp &
	RevisionsProp &
	UserProp &
	LoginProp &
	LogoutProp &
	UpdateDocProp &
	ShareDocProp &
	LoadingProp;

export type DocState = {
	value: DocContent,
	modifiedTime: Date,
	widgets: Widget[]
} & ThemeProp;

export type ChildrenProps = {
	children: React.Node
};

export type ErrorBoundaryState = {
	error: ?{
		name: string,
		message: string,
		stack: string
	}
};

export type OutputProp = {
	output_type: string
} & $PropertyType<Message, 'content'>;

export type OutputProps = {
	kernel: ?KernelState
};

export type OutputState = {
	messages: { [parent_id: string]: Message[] },
	output: ?OutputProp
};

export type CodeProps = RenderNodeProps & {
	block?: ?Slate.Block,
	decorations?: Immutable.List<Slate.Decoration>,
	theme: 'light' | 'dark'
};

export type CodeState = {};

export type CodeOutputProps = {
	source: string,
	language: LanguageName,
	getValue: () => Value,
	getBlockData: (key?: string) => any,
	setBlockData: IndexedObject => void,
	copyBlockURI: () => void
};

export type CodeOutputState = {
	parent: ?string,
	messages: { [parent_id: string]: Message[] },
	output: ?OutputProp,
	status: KernelStatus
};
