// @flow

import globalPlugins from './global';
import hotkeyPlugins from './hotkey';
import markdownPlugins from './markdown';
import pastePlugins from './paste';
import listPlugins from './list';
import { commands, queries } from './commands';
import { excludeCodeBlocks } from './utils';
import './plugins.css';

const plugins = [
	excludeCodeBlocks(hotkeyPlugins),
	excludeCodeBlocks(listPlugins),
	excludeCodeBlocks(markdownPlugins),
	excludeCodeBlocks(pastePlugins),
	globalPlugins,
	commands,
	queries
];

export default plugins;
