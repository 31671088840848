// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import Doc from './Doc';
import ErrorBoundary from './ErrorBoundary';
// import { register, unregister } from './serviceWorker';
import './index.css';

ReactDOM.render(
	<ErrorBoundary>
		<Doc />
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/0febe269b555d922f7726f30d505ed5cca2598ec/docusaurus/docs/making-a-progressive-web-app.md
// register();
