// @flow

export const lightTheme = {
	base: 'vs',
	inherit: true,
	rules: [
		{
			token: '',
			background: 'f6f8fa',
			foreground: '24292e'
		}
	],
	colors: {
		'editor.background': '#f6f8fa',
		'editor.foreground': '#24292e',
		'editor.lineHighlightBackground': '#0000FF20',
		'editorIndentGuide.background': '#eaeced',
		'editorIndentGuide.activeBackground': '#d5d8d9',
		'editorLineNumber.foreground': '#d5d8d9',
		'scrollbar.shadow': 'transparent',
		focusBorder: 'transparent',
		contrastBorder: 'transparent',
		'scrollbarSlider.background': '#eaeced',
		'scrollbarSlider.hoverBackground': '#d5d8d9',
		'scrollbarSlider.activeBackground': '#d5d8d9'
	}
};

export const darkTheme = {
	base: 'vs-dark',
	inherit: true,
	rules: [
		{
			token: '',
			background: '304554',
			foreground: 'f5f8fa'
		}
	],
	colors: {
		'editor.background': '#304554',
		'editor.foreground': '#f5f8fa',
		'editorIndentGuide.background': '#3b5260',
		'editorIndentGuide.activeBackground': '#506471',
		'editorLineNumber.foreground': '#506471',
		'scrollbar.shadow': 'transparent',
		focusBorder: 'transparent',
		contrastBorder: 'transparent',
		'scrollbarSlider.background': '#3b5260',
		'scrollbarSlider.hoverBackground': '#3b5260',
		'scrollbarSlider.activeBackground': '#506471'
	}
};

export const oneLightTheme = {
	base: 'vs',
	inherit: true,
	colors: {
		'editor.foreground': '#383942',
		'editor.background': '#fafafa',
		'editor.selectionBackground': '#000000',
		'editor.inactiveSelectionBackground': '#000000',
		'editor.lineHighlightBackground': '#000000',
		'editorCursor.foreground': '#4179f1',
		'editorIndentGuide.background': '#000000',
		'editorIndentGuide.activeBackground': '#000000',
		'editor.selectionHighlightBorder': '#000000'
	},
	rules: [
		{
			foreground: 'a0a1a7',
			fontStyle: 'italic',
			token: 'comment'
		},
		{
			foreground: '85868e',
			token: 'punctuation.definition'
		},
		{
			foreground: 'a0a1a7',
			fontStyle: 'italic',
			token: 'punctuation.definition.comment'
		},
		{
			foreground: '85868e',
			token: 'punctuation.definition.annotation'
		},
		{
			foreground: '85868e',
			token: 'punctuation.definition.heading'
		},
		{
			foreground: '85868e',
			token: 'punctuation.definition.list_item'
		},
		{
			foreground: '85868e',
			token:
				'(text punctuation.definition.italic | text punctuation.definition.bold)'
		},
		{
			background: '000000',
			token: 'meta.separator.thematic-break'
		},
		{
			foreground: '85868e',
			token: 'punctuation.definition.thematic-break'
		},
		{
			foreground: '000000',
			background: '000000',
			token: 'punctuation.definition.blockquote'
		},
		{
			foreground: '85868e',
			token: 'punctuation.separator'
		},
		{
			foreground: '85868e',
			token: 'punctuation.terminator'
		},
		{
			foreground: '85868e',
			token: 'punctuation.accessor'
		},
		{
			foreground: '85868e',
			token: 'punctuation.section'
		},
		{
			foreground: '50a14f',
			token: 'string'
		},
		{
			foreground: '946500',
			token: 'constant.numeric'
		},
		{
			foreground: '000000',
			token: 'constant.numeric.line-number'
		},
		{
			foreground: 'bd8100',
			token: 'constant.numeric.line-number.match'
		},
		{
			foreground: 'e45649',
			fontStyle: 'italic',
			token: 'constant.language'
		},
		{
			foreground: 'a725a5',
			token: 'constant.character'
		},
		{
			foreground: 'a725a5',
			token: 'constant.other'
		},
		{
			foreground: 'e45649',
			token: 'variable.member'
		},
		{
			foreground: '383942',
			token: 'variable.parameter'
		},
		{
			foreground: 'e45649',
			fontStyle: 'italic',
			token: 'variable.language'
		},
		{
			foreground: '4179f1',
			token: 'variable.function'
		},
		{
			foreground: '4179f1',
			token: 'variable.annotation'
		},
		{
			foreground: 'a725a5',
			token: 'keyword - keyword.operator'
		},
		{
			foreground: 'a725a5',
			token: 'keyword.operator.word'
		},
		{
			foreground: 'ad7600',
			token: 'keyword.operator'
		},
		{
			foreground: 'e45649',
			token: 'storage'
		},
		{
			foreground: 'a725a5',
			fontStyle: 'italic',
			token: 'storage.type'
		},
		{
			foreground: 'bd8100',
			token:
				'entity.name - (entity.name.section | entity.name.tag | entity.name.label)'
		},
		{
			foreground: '4179f1',
			token: 'entity.name.function'
		},
		{
			foreground: '4179f1',
			token: 'entity.name.label'
		},
		{
			foreground: '50a14f',
			fontStyle: 'italic',
			token: 'entity.other.inherited-class'
		},
		{
			foreground: 'e45649',
			token: 'entity.name.tag'
		},
		{
			foreground: 'a725a5',
			fontStyle: 'italic',
			token: 'entity.other.attribute-name'
		},
		{
			foreground: '4179f1',
			fontStyle: 'italic',
			token: 'support.function'
		},
		{
			foreground: '4179f1',
			fontStyle: 'italic',
			token: 'support.macro'
		},
		{
			foreground: '946500',
			fontStyle: 'italic',
			token: 'support.constant'
		},
		{
			foreground: '4179f1',
			fontStyle: 'italic',
			token: 'support.type'
		},
		{
			foreground: '4179f1',
			fontStyle: 'italic',
			token: 'support.class'
		},
		{
			foreground: 'a94137',
			background: 'f4d0cd',
			token: 'invalid'
		},
		{
			foreground: '704d00',
			background: 'e0d6bd',
			token: 'invalid.deprecated'
		},
		{
			foreground: 'e45649',
			token: 'markup.error'
		},
		{
			foreground: 'e45649',
			token: 'message.error'
		},
		{
			foreground: 'bd8100',
			token: 'markup.warning'
		},
		{
			foreground: '4179f1',
			token: 'markup.info'
		},
		{
			foreground: 'e45649',
			token: 'markup.deleted'
		},
		{
			foreground: '50a14f',
			token: 'markup.inserted'
		},
		{
			foreground: '4179f1',
			token: 'markup.changed'
		},
		{
			foreground: 'adaeb3',
			token: 'markup.ignored'
		},
		{
			foreground: 'adaeb3',
			token: 'markup.untracked'
		},
		{
			fontStyle: 'bold',
			token: 'markup.bold'
		},
		{
			fontStyle: 'italic',
			token: 'markup.italic'
		},
		{
			fontStyle: 'bold italic',
			token: 'markup.italic markup.bold | markup.bold markup.italic'
		},
		{
			foreground: 'e45649',
			fontStyle: 'bold',
			token: 'markup.heading'
		},
		{
			foreground: '4179f1',
			token: 'markup.underline.link'
		},
		{
			foreground: '4179f1',
			token: 'string.other.link'
		},
		{
			foreground: '50a14f',
			token: 'string.other.link.description'
		},
		{
			foreground: '85868e',
			token: 'markup.list.numbered.bullet'
		},
		{
			foreground: '787982',
			token: 'markup.quote'
		},
		{
			background: '000000',
			token: 'markup.raw'
		},
		{
			background: '000000',
			token: 'markup.raw.inline'
		},
		{
			background: '000000',
			token: 'meta.table.header.markdown'
		},
		{
			background: '000000',
			token: 'meta.table.header-separator.markdown'
		},
		{
			background: '000000',
			token: 'meta.table.markdown'
		},
		{
			foreground: '383942',
			fontStyle: 'normal',
			token: 'support.type.property-name'
		},
		{
			foreground: '383942',
			fontStyle: 'normal',
			token: 'support.type.custom-property.name'
		},
		{
			foreground: 'a725a5',
			fontStyle: 'normal',
			token: 'entity.other.attribute-name.class.css'
		},
		{
			foreground: 'bd8100',
			fontStyle: 'normal',
			token: 'entity.other.attribute-name.id.css'
		},
		{
			foreground: 'e45649',
			token: 'keyword.other.important'
		},
		{
			foreground: '85868e',
			token: 'support.type.vendor-prefix'
		},
		{
			foreground: '5f6067',
			token: 'entity.other.pseudo-class'
		},
		{
			foreground: '5f6067',
			token: 'entity.other.pseudo-element'
		},
		{
			foreground: 'a725a5',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'bd8100',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '4179f1',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '946500',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'e45649',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'a725a5',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'bd8100',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '4179f1',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '946500',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '50a14f',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '383942',
			token: 'brackethighlighter.default'
		},
		{
			foreground: 'e45649',
			token: 'brackethighlighter.unmatched'
		},
		{
			foreground: '50a14f',
			token: 'brackethighlighter.quote'
		},
		{
			foreground: 'a725a5',
			token: 'brackethighlighter.curly'
		},
		{
			foreground: 'bd8100',
			token: 'brackethighlighter.round'
		},
		{
			foreground: '4179f1',
			token: 'brackethighlighter.square'
		},
		{
			foreground: '946500',
			token: 'brackethighlighter.angle'
		},
		{
			foreground: '50a14f',
			token: 'brackethighlighter.tag'
		},
		{
			foreground: 'ffffff',
			token: 'gutter_color'
		},
		{
			foreground: 'ffffff',
			token: 'mcol'
		},
		{
			foreground: 'e45649',
			token: 'anaconda.outline.illegal'
		},
		{
			foreground: 'e45649',
			token: 'anaconda.underline.illegal'
		},
		{
			foreground: 'bd8100',
			token: 'anaconda.outline.warning'
		},
		{
			foreground: 'bd8100',
			token: 'anaconda.underline.warning'
		},
		{
			foreground: '4179f1',
			token: 'anaconda.outline.violation'
		},
		{
			foreground: '4179f1',
			token: 'anaconda.underline.violation'
		},
		{
			foreground: 'ffffff',
			token: 'sublimelinter.gutter-mark'
		},
		{
			foreground: 'e45649',
			token: 'sublimelinter.mark.error'
		},
		{
			foreground: 'bd8100',
			token: 'sublimelinter.mark.warning'
		}
	]
};

export const oneDarkTheme = {
	base: 'vs-dark',
	inherit: true,
	colors: {
		'editor.foreground': '#abb2bf',
		'editor.background': '#262a31',
		'editor.selectionBackground': '#ffffff',
		'editor.inactiveSelectionBackground': '#ffffff',
		'editor.lineHighlightBackground': '#ffffff',
		'editorCursor.foreground': '#61afef',
		'editorIndentGuide.background': '#ffffff',
		'editorIndentGuide.activeBackground': '#ffffff',
		'editor.selectionHighlightBorder': '#ffffff'
	},
	rules: [
		{
			foreground: '5c6370',
			fontStyle: 'italic',
			token: 'comment'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.definition'
		},
		{
			foreground: '5c6370',
			fontStyle: 'italic',
			token: 'punctuation.definition.comment'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.definition.annotation'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.definition.heading'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.definition.list_item'
		},
		{
			foreground: '737c8c',
			token:
				'(text punctuation.definition.italic | text punctuation.definition.bold)'
		},
		{
			background: 'ffffff',
			token: 'meta.separator.thematic-break'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.definition.thematic-break'
		},
		{
			foreground: 'ffffff',
			background: 'ffffff',
			token: 'punctuation.definition.blockquote'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.separator'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.terminator'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.accessor'
		},
		{
			foreground: '737c8c',
			token: 'punctuation.section'
		},
		{
			foreground: '98c27a',
			token: 'string'
		},
		{
			foreground: 'd19a66',
			token: 'constant.numeric'
		},
		{
			foreground: 'ffffff',
			token: 'constant.numeric.line-number'
		},
		{
			foreground: 'e4be77',
			token: 'constant.numeric.line-number.match'
		},
		{
			foreground: 'de6872',
			fontStyle: 'italic',
			token: 'constant.language'
		},
		{
			foreground: 'c678dd',
			token: 'constant.character'
		},
		{
			foreground: 'c678dd',
			token: 'constant.other'
		},
		{
			foreground: 'de6872',
			token: 'variable.member'
		},
		{
			foreground: 'abb2bf',
			token: 'variable.parameter'
		},
		{
			foreground: 'de6872',
			fontStyle: 'italic',
			token: 'variable.language'
		},
		{
			foreground: '61afef',
			token: 'variable.function'
		},
		{
			foreground: '61afef',
			token: 'variable.annotation'
		},
		{
			foreground: 'c678dd',
			token: 'keyword - keyword.operator'
		},
		{
			foreground: 'c678dd',
			token: 'keyword.operator.word'
		},
		{
			foreground: 'dcb16f',
			token: 'keyword.operator'
		},
		{
			foreground: 'de6872',
			token: 'storage'
		},
		{
			foreground: 'c678dd',
			fontStyle: 'italic',
			token: 'storage.type'
		},
		{
			foreground: 'e4be77',
			token:
				'entity.name - (entity.name.section | entity.name.tag | entity.name.label)'
		},
		{
			foreground: '61afef',
			token: 'entity.name.function'
		},
		{
			foreground: '61afef',
			token: 'entity.name.label'
		},
		{
			foreground: '98c27a',
			fontStyle: 'italic',
			token: 'entity.other.inherited-class'
		},
		{
			foreground: 'de6872',
			token: 'entity.name.tag'
		},
		{
			foreground: 'c678dd',
			fontStyle: 'italic',
			token: 'entity.other.attribute-name'
		},
		{
			foreground: '61afef',
			fontStyle: 'italic',
			token: 'support.function'
		},
		{
			foreground: '61afef',
			fontStyle: 'italic',
			token: 'support.macro'
		},
		{
			foreground: 'd19a66',
			fontStyle: 'italic',
			token: 'support.constant'
		},
		{
			foreground: '61afef',
			fontStyle: 'italic',
			token: 'support.type'
		},
		{
			foreground: '61afef',
			fontStyle: 'italic',
			token: 'support.class'
		},
		{
			foreground: 'e68e95',
			background: '543b41',
			token: 'invalid'
		},
		{
			foreground: 'dcb48e',
			background: '51463e',
			token: 'invalid.deprecated'
		},
		{
			foreground: 'de6872',
			token: 'markup.error'
		},
		{
			foreground: 'de6872',
			token: 'message.error'
		},
		{
			foreground: 'e4be77',
			token: 'markup.warning'
		},
		{
			foreground: '61afef',
			token: 'markup.info'
		},
		{
			foreground: 'de6872',
			token: 'markup.deleted'
		},
		{
			foreground: '98c27a',
			token: 'markup.inserted'
		},
		{
			foreground: '61afef',
			token: 'markup.changed'
		},
		{
			foreground: '505762',
			token: 'markup.ignored'
		},
		{
			foreground: '505762',
			token: 'markup.untracked'
		},
		{
			fontStyle: 'bold',
			token: 'markup.bold'
		},
		{
			fontStyle: 'italic',
			token: 'markup.italic'
		},
		{
			fontStyle: 'bold italic',
			token: 'markup.italic markup.bold | markup.bold markup.italic'
		},
		{
			foreground: 'de6872',
			fontStyle: 'bold',
			token: 'markup.heading'
		},
		{
			foreground: '61afef',
			token: 'markup.underline.link'
		},
		{
			foreground: '61afef',
			token: 'string.other.link'
		},
		{
			foreground: '98c27a',
			token: 'string.other.link.description'
		},
		{
			foreground: '737c8c',
			token: 'markup.list.numbered.bullet'
		},
		{
			foreground: '818998',
			token: 'markup.quote'
		},
		{
			background: 'ffffff',
			token: 'markup.raw'
		},
		{
			background: 'ffffff',
			token: 'markup.raw.inline'
		},
		{
			background: 'ffffff',
			token: 'meta.table.header.markdown'
		},
		{
			background: 'ffffff',
			token: 'meta.table.header-separator.markdown'
		},
		{
			background: 'ffffff',
			token: 'meta.table.markdown'
		},
		{
			foreground: 'abb2bf',
			fontStyle: 'normal',
			token: 'support.type.property-name'
		},
		{
			foreground: 'abb2bf',
			fontStyle: 'normal',
			token: 'support.type.custom-property.name'
		},
		{
			foreground: 'c678dd',
			fontStyle: 'normal',
			token: 'entity.other.attribute-name.class.css'
		},
		{
			foreground: 'e4be77',
			fontStyle: 'normal',
			token: 'entity.other.attribute-name.id.css'
		},
		{
			foreground: 'de6872',
			token: 'keyword.other.important'
		},
		{
			foreground: '737c8c',
			token: 'support.type.vendor-prefix'
		},
		{
			foreground: '9da3af',
			token: 'entity.other.pseudo-class'
		},
		{
			foreground: '9da3af',
			token: 'entity.other.pseudo-element'
		},
		{
			foreground: 'c678dd',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'e4be77',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '61afef',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'd19a66',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'de6872',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'c678dd',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'e4be77',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '61afef',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'd19a66',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: '98c27a',
			token:
				'source.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.value.json meta.structure.dictionary.json meta.structure.dictionary.key.json string.quoted.double.json'
		},
		{
			foreground: 'abb2bf',
			token: 'brackethighlighter.default'
		},
		{
			foreground: 'de6872',
			token: 'brackethighlighter.unmatched'
		},
		{
			foreground: '98c27a',
			token: 'brackethighlighter.quote'
		},
		{
			foreground: 'c678dd',
			token: 'brackethighlighter.curly'
		},
		{
			foreground: 'e4be77',
			token: 'brackethighlighter.round'
		},
		{
			foreground: '61afef',
			token: 'brackethighlighter.square'
		},
		{
			foreground: 'd19a66',
			token: 'brackethighlighter.angle'
		},
		{
			foreground: '98c27a',
			token: 'brackethighlighter.tag'
		},
		{
			foreground: 'de6872',
			token: 'anaconda.outline.illegal'
		},
		{
			foreground: 'de6872',
			token: 'anaconda.underline.illegal'
		},
		{
			foreground: 'e4be77',
			token: 'anaconda.outline.warning'
		},
		{
			foreground: 'e4be77',
			token: 'anaconda.underline.warning'
		},
		{
			foreground: '61afef',
			token: 'anaconda.outline.violation'
		},
		{
			foreground: '61afef',
			token: 'anaconda.underline.violation'
		},
		{
			foreground: 'ffffff',
			token: 'sublimelinter.gutter-mark'
		},
		{
			foreground: 'de6872',
			token: 'sublimelinter.mark.error'
		},
		{
			foreground: 'e4be77',
			token: 'sublimelinter.mark.warning'
		}
	]
};
