// @flow

import { kernels } from 'rx-jupyter';
import { executeRequest } from '@nteract/messaging';
import { getHost } from './storage.js';
import * as types from '../../types';

const binder: types.KernelMap = {};

function getKernelForLanguage(
	language: string
): { repo?: string, kernelName?: string } {
	switch (language) {
		case 'nodejs':
			return {
				repo: 'gnestor/javascript-binder',
				kernelName: 'nodejs'
			};
		case 'bash':
			return {
				repo: 'gnestor/bash-binder',
				kernelName: 'bash'
			};
		case 'r':
			return {
				repo: 'binder-examples/r',
				kernelName: 'ir'
			};
		case 'julia':
			return {
				repo: 'binder-examples/julia-python',
				kernelName: 'julia-0.6'
			};
		case 'python':
		default:
			return {
				repo: 'nteract/vdom',
				kernelName: 'python'
			};
	}
}

function getKernel(options: types.KernelOptions): Promise<types.KernelState> {
	const { host, kernelName } = options;
	return kernels
		.start(host, kernelName, '/')
		.toPromise()
		.then(xhr => {
			return {
				...xhr.response,
				channels: kernels.connect(
					host,
					xhr.response.id
				),
				last_activity: new Date(xhr.response.last_activity)
			};
		});
}

export async function getBinder(language: string): Promise<types.KernelState> {
	const { repo, kernelName } = getKernelForLanguage(language);
	if (repo && kernelName) {
		const host = await getHost({
			repo,
			ref: 'master',
			binderURL: 'https://mybinder.org'
		});
		binder[language] = await getKernel({ kernelName, host });
		return binder[language];
	}
}

export function createMessage(source: string) {
	return executeRequest(source);
}
