// @flow

import * as React from 'react';
import { Value } from 'slate';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { Helmet as Head } from 'react-helmet';
import moment from 'moment';
import { Popover, PopoverInteractionKind, Classes } from '@blueprintjs/core';
import Code from './Code';
import { CheckListItem } from './plugins/list';
import MenuWithHotkey from './Menu';
import { withAuth, withDoc } from './containers';
import schema from './schema';
import plugins from './plugins';
import * as types from './types';
import './Doc.css';

moment.relativeTimeThreshold('ss', 2);

class Image extends React.Component {
	state = { src: null };

	componentDidMount() {
		const { node } = this.props;
		const src = node.data.get('src');
		const file = node.data.get('file');
		if (src) {
			this.setState({ src });
		} else if (file && file.size) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.setState({ src: reader.result });
			});
			reader.readAsDataURL(file);
		}
	}

	render() {
		const { attributes, isFocused } = this.props;
		const { src } = this.state;
		return src ? (
			<img
				{...attributes}
				src={src}
				alt=""
				className={isFocused ? 'focused' : 'unfocused'}
			/>
		) : (
			<div {...attributes}>Loading...</div>
		);
	}
}

class Doc extends React.PureComponent<types.DocProps, types.DocState> {
	constructor(props: types.DocProps) {
		super(props);
		const { doc } = props;
		const value = Value.fromJSON(doc.content);
		const { modifiedTime } = doc;
		this.state = {
			value,
			modifiedTime,
			theme: 'light',
			widgets: []
		};
	}

	editor: React.ElementRef<Editor>;

	async componentDidMount() {
		const widgets = await fetch('/widgets.json').then(response =>
			response.json()
		);
		this.setState({ widgets });
	}

	componentDidUpdate(prevProps: types.DocProps) {
		if (
			this.props.doc.modifiedTime !== prevProps.doc.modifiedTime &&
			this.props.doc.modifiedTime > this.state.modifiedTime
		) {
			console.log('Doc: componentDidUpdate');
			const value = Value.fromJSON(this.props.doc.content);
			this.setState({ value });
		}
	}

	/*
	 * Handle edit change
	 */
	onChange = (change: types.Change): void => {
		const { value } = change;
		if (value.document !== this.state.value.document) {
			const modifiedTime = new Date();
			const name = this.editor.getTitle() || this.props.doc.name || 'Untitled';
			const content = value.toJSON();
			const text = Plain.serialize(value);
			this.setState({ modifiedTime }, () => {
				this.props.updateDoc(
					{ ...this.props.doc, name, content, modifiedTime },
					text
				);
			});
		}
		this.setState({ value });
	};

	render() {
		const { doc, user, loading } = this.props;
		const { value, theme } = this.state;
		const isDarkTheme =
			theme === 'dark' ||
			window.matchMedia('(prefers-color-scheme: dark)').matches;
		const readOnly = !doc.capabilities.canEdit;
		return (
			<div className="content">
				<Head>
					<title>
						{doc.id
							? `${doc.name.replace('.ddoc', '')} - Dynamic Docs`
							: 'Dynamic Docs'}
					</title>
					<meta
						name="theme-color"
						content={isDarkTheme ? '#30404d' : '#ffffff'}
					/>
					<meta property="og:title" content={doc.name} />
					<link
						key="prism-stylesheet"
						rel="stylesheet"
						href={
							isDarkTheme === 'light'
								? '/css/prism-tomorrow.css'
								: '/css/prism.css'
						}
					/>
					<body className={isDarkTheme ? Classes.DARK : ''} />
				</Head>
				{/* <div className={`doc markdown ${Classes.RUNNING_TEXT}`}> */}
				<div className={loading ? `doc markdown loading` : 'doc markdown'}>
					{this.renderMenus()}
					<Editor
						ref={element => {
							this.editor = element;
						}}
						value={value}
						onChange={this.onChange}
						renderMark={this.renderMark}
						renderNode={this.renderNode}
						plugins={plugins}
						schema={schema}
						autoCorrect={true}
						autoFocus={true}
						readOnly={readOnly}
						spellCheck={true}
					/>
				</div>
			</div>
		);
	}

	renderMenus = (): React.Node => {
		const { docs } = this.props;
		const { widgets, theme } = this.state;
		const docsMenuItems = docs.map(doc => ({
			name: doc.name,
			label: moment(doc.modifiedTime).calendar(),
			onSelect: () => {
				window.open(`/${doc.id}`, '_blank');
			}
		}));
		const widgetMenuItems = widgets.map(widget => ({
			name: widget.name,
			label: widget.label,
			onSelect: () => {
				if (widget.data) {
					this.editor.setBlocks({
						type: widget.type,
						data: {
							source: widget.data.source,
							language: widget.data.language || 'javascript',
							mode: widget.data.mode || 'output'
						}
					});
				} else {
					this.editor.setBlocks(widget.type);
				}
				this.editor.focus();
			}
		}));
		const globalMenuItems = [
			{
				name: `Toggle ${theme === 'light' ? 'dark' : 'light'} theme`,
				label: 'View',
				onSelect: () => {
					this.setState({ theme: theme === 'light' ? 'dark' : 'light' });
				}
			}
		];
		return (
			<React.Fragment>
				<MenuWithHotkey
					name="Commands menu"
					items={[...globalMenuItems, ...widgetMenuItems]}
					hotkey="meta+shift+p"
				/>
				<MenuWithHotkey
					name="Docs menu"
					items={docsMenuItems}
					hotkey="meta+shift+o"
				/>
			</React.Fragment>
		);
	};

	renderMark = (props: types.RenderMarkProps): React.Node => {
		const { children, mark, attributes } = props;
		switch (mark.type) {
			case 'italic':
				return <em {...attributes}>{children}</em>;
			case 'bold':
				return <strong {...attributes}>{children}</strong>;
			case 'underline':
				return <u {...attributes}>{children}</u>;
			case 'strikethrough':
				return <s {...attributes}>{children}</s>;
			case 'code':
				return <code {...attributes}>{children}</code>;
			default:
				return <span {...attributes}>{children}</span>;
		}
	};

	renderNode = (props: types.RenderNodeProps): React.Node => {
		const { attributes, children, node, isFocused } = props;
		switch (node.type) {
			case 'paragraph':
				return <p {...attributes}>{children}</p>;
			case 'title':
				return (
					<h1 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						{children}
					</h1>
				);
			case 'heading-one':
				return (
					<h2 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						<a
							href={`#${node.text.replace(' ', '-').toLowerCase()}`}
							className="anchor"
							contentEditable={false}
						>
							#
						</a>
						{children}
					</h2>
				);
			case 'heading-two':
				return (
					<h3 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						<a
							href={`#${node.text.replace(' ', '-').toLowerCase()}`}
							className="anchor"
							contentEditable={false}
						>
							#
						</a>
						{children}
					</h3>
				);
			case 'heading-three':
				return (
					<h4 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						<a
							href={`#${node.text.replace(' ', '-').toLowerCase()}`}
							className="anchor"
							contentEditable={false}
						>
							#
						</a>
						{children}
					</h4>
				);
			case 'heading-four':
				return (
					<h5 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						<a
							href={`#${node.text.replace(' ', '-').toLowerCase()}`}
							className="anchor"
							contentEditable={false}
						>
							#
						</a>
						{children}
					</h5>
				);
			case 'heading-five':
				return (
					<h6 id={node.text.replace(' ', '-').toLowerCase()} {...attributes}>
						<a
							href={`#${node.text.replace(' ', '-').toLowerCase()}`}
							className="anchor"
							contentEditable={false}
						>
							#
						</a>
						{children}
					</h6>
				);
			case 'quote':
				return <blockquote {...attributes}>{children}</blockquote>;
			case 'code':
				return <Code {...props} theme={this.state.theme} />;
			case 'unordered-list':
				return <ul {...attributes}>{children}</ul>;
			case 'ordered-list':
				return <ol {...attributes}>{children}</ol>;
			case 'list-item':
				return <li {...attributes}>{children}</li>;
			case 'check-list-item':
				return <CheckListItem {...props} />;
			case 'footnote':
				return <sup {...attributes}>{children}</sup>;
			case 'divider':
				return (
					<hr className={isFocused ? 'focused' : 'unfocused'} {...attributes} />
				);
			case 'link':
				const href = node.data.get('href');
				return (
					<Popover
						content={
							<div className="link-popover">
								<a
									{...attributes}
									href={href}
									target={
										href && href.startsWith(window.location.origin)
											? '_self'
											: '_blank'
									}
								>
									{href}
								</a>
							</div>
						}
						interactionKind={PopoverInteractionKind.HOVER}
						position="top"
					>
						<a {...attributes} href={href}>
							{children}
						</a>
					</Popover>
				);
			case 'image':
				return (
					<img
						src={node.data.get('src')}
						alt=""
						className={isFocused ? 'focused' : 'unfocused'}
						{...attributes}
					/>
				);
			case 'table':
				return <table {...attributes}>{children}</table>;
			default:
				return <p {...attributes}>{children}</p>;
		}
	};
}

export default withAuth(withDoc(Doc));
