// @flow

import * as React from 'react';
import AWS from 'aws-sdk/global';
import { device } from 'aws-iot-device-sdk';
import { Login } from './Login';
import { UserMenu, ShareMenu, RevisionsMenu } from './Toolbar';
import { GenericError } from './Errors';
import * as types from './types';

AWS.config.credentials = new AWS.CognitoIdentityCredentials(
	{ IdentityPoolId: 'us-east-1:0dc043a3-6576-4340-8066-e51ce6e8ae19' },
	{ region: 'us-east-1' }
);

const MIME_TYPE = 'application/vnd.ddoc';

const INITIAL_STATE = {
	doc: {},
	docs: [],
	docSuggestions: {},
	tagSuggestions: {},
	revisions: [],
	user: {}
};

const DOC_TEMPLATE = {
	document: {
		nodes: [
			{
				object: 'block',
				type: 'title',
				nodes: [
					{
						object: 'text',
						leaves: [
							{
								text: 'Untitled'
							}
						]
					}
				]
			},
			{
				object: 'block',
				type: 'paragraph',
				nodes: [
					{
						object: 'text',
						leaves: [
							{
								text: ''
							}
						]
					}
				]
			}
		]
	}
};

/*
 * Replace Google Drive create/open URL with a normalized one
 */
if (window.location.search.length > 0) {
	const { state } = queryStringToObject(window.location.search);
	const id = getIdFromDriveState(state);
	if (id) {
		window.history.replaceState(
			state,
			'Dynamic Docs',
			`${window.location.origin}/${id}`
		);
	}
}

/*
 * Workaround to allow Flow to type check `Object.values`
 */
export function values<T>(obj: { [string]: T }): Array<T> {
	return Object.keys(obj).map(key => obj[key]);
}

/*
 * Convert object to URL query string
 */
export function objectToQueryString(obj: types.IndexedObject): string {
	return Object.keys(obj)
		.map(key => `${key}=${encodeURIComponent(obj[key])}`)
		.join('&');
}

/*
 * Convert URL query string to object
 */
export function queryStringToObject(query: string): types.IndexedObject {
	return query
		.substring(1)
		.split('&')
		.reduce((result, item) => {
			const [key, value] = item.split('=');
			try {
				return { ...result, [key]: JSON.parse(decodeURIComponent(value)) };
			} catch (error) {
				return { ...result, [key]: decodeURIComponent(value) };
			}
		}, {});
}

/*
 * Get doc ID from the "state" object from Google Drive URL query string
 * (on create/open doc from Google Drive)
 */
export function getIdFromDriveState(state: types.DriveState): string | null {
	switch (state.action) {
		case 'open':
			const [id] = state.ids;
			return id;
		case 'create':
		default:
			return null;
	}
}

/*
 * Get base64 URL string from image URL
 */
export function getBase64FromImageURL(url: string): Promise<string> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.setAttribute('crossOrigin', 'anonymous');
		// img.width = 96;
		// img.height = 96;
		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
			const format = img.src.match(/\.(\w+)$/)[1];
			resolve(canvas.toDataURL(`image/${format}`));
		};
		img.onerror = () => reject();
		img.src = url;
	});
}

/*
 * Normalize doc response from Google Drive
 */
function normalizeDoc(doc: types.IndexedObject): types.Doc {
	return {
		...doc,
		createdTime: new Date(doc.createdTime),
		modifiedTime: new Date(doc.modifiedTime),
		modifiedByMeTime: new Date(doc.modifiedByMeTime),
		// eslint-disable-next-line
		size: parseInt(doc.size),
		// eslint-disable-next-line
		version: parseInt(doc.version)
	};
}

/*
 * Normalize revision response from Google Drive
 */
function normalizeRevision(revision: types.IndexedObject): types.DriveRevision {
	return {
		...revision,
		modifiedTime: new Date(revision.modifiedTime)
	};
}

/*
 * Create error from Google Drive response
 */
function createError(error: types.DriveRequestError): Error {
	if (error instanceof Error) return error;
	if (error.result) {
		const newError = new Error(error.result.error.message);
		newError.name = error.result.error.code.toString();
		newError.stack = JSON.stringify(error.result.error.errors, null, 2);
		return newError;
	}
	return new Error(error);
}

function initializeGoogle(): Promise<types.User | {}> {
	const clientId =
		'770963638837-34bou5mp30t9nr5g7ttmn26j6q9t1rem.apps.googleusercontent.com';
	const apiKey = 'AIzaSyCKbn7iJ0RLR_jAurwTABFukQNTNq7fT5U';
	const discoveryDocs = [
		'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
	];
	const scope =
		'email profile https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.install';
	return new Promise((resolve, reject) => {
		if (!navigator.onLine || !window.gapi)
			return reject(new Error('No network connection'));
		window.gapi.load('client:auth2', {
			callback: () => {
				resolve();
			},
			onerror: () => {
				reject();
			},
			timeout: 5000,
			ontimeout: function() {
				reject();
			}
		});
	})
		.then(() => {
			return window.gapi.client.init({
				clientId,
				apiKey,
				discoveryDocs,
				scope
			});
		})
		.then(() => {
			const GoogleAuth = window.gapi.auth2.getAuthInstance();
			if (GoogleAuth.isSignedIn.get()) {
				return getGoogleProfile();
			}
			return {};
		});
}

function getDriveShareClient() {
	return new Promise((resolve, reject) => {
		window.gapi.load('drive-share', () => {
			resolve();
		});
	}).then(() => {
		window.googleShareClient = new window.gapi.drive.share.ShareClient();
		window.googleShareClient.setOAuthToken(
			window.gapi.client.getToken().access_token
		);
	});
}

function signInWithGoogle(): Promise<types.User> {
	const GoogleAuth = window.gapi.auth2.getAuthInstance();
	return GoogleAuth.signIn().then(() => getGoogleProfile());
}

export function signOutWithGoogle(): void {
	const GoogleAuth = window.gapi.auth2.getAuthInstance();
	return GoogleAuth.signOut();
}

function getGoogleProfile(): types.User {
	const GoogleAuth = window.gapi.auth2.getAuthInstance();
	const profile = GoogleAuth.currentUser.get().getBasicProfile();
	const id = profile.getId();
	const name = profile.getName();
	const email = profile.getEmail();
	const picture = profile.getImageUrl();
	window.gtag('config', 'UA-126652382-1', {
		user_id: 'USER_ID'
	});
	getDriveShareClient();
	return {
		id,
		name,
		email,
		picture
	};
}

/*
 * Save user to local storage
 */
async function setUserCache(user: types.User): Promise<void> {
	const picture = await getBase64FromImageURL(user.picture);
	window.localStorage['commonlayer.com/user'] = JSON.stringify(
		{ ...user, picture },
		null,
		2
	);
}

/*
 * Get user from local storage
 */
function getUserCache(): types.User | {} {
	try {
		return JSON.parse(window.localStorage['commonlayer.com/user']);
	} catch (error) {
		return INITIAL_STATE.user;
	}
}

/*
 * Save doc to local storage
 */
function setDocCache(doc: types.Doc): void {
	window.localStorage[`commonlayer.com/${doc.id}`] = JSON.stringify(
		doc,
		null,
		2
	);
}

/*
 * Get doc from local storage
 */
function getDocCache(id: string): types.Doc | {} {
	try {
		return normalizeDoc(
			JSON.parse(window.localStorage[`commonlayer.com/${id}`])
		);
	} catch (error) {
		return INITIAL_STATE.doc;
	}
}

/*
 * Send a request to Google Drive
 */
function driveRequest(options: Object): Promise<Object> {
	return new Promise((resolve, reject) => {
		const defaultHeaders = {
			'Accept-Encoding': 'gzip',
			'User-Agent': 'Dynamic Docs (gzip)'
		};
		if (!window.navigator.onLine || !window.gapi || !window.gapi.client)
			return reject(new Error('No network connection'));
		resolve(
			window.gapi.client.request({
				...options,
				headers: { ...defaultHeaders, ...options.headers }
			})
		);
		// const {path, ...otherOptions} = options;
		// const defaultHeaders = {
		// 	'Authorization': `Bearer ${accessToken}`,
		// 	'Accept-Encoding': 'gzip',
		// 	'User-Agent': 'Dynamic Docs (gzip)'
		// };
		// const { access_token: accessToken } = window.gapi.client.getToken();
		// resolve(fetch(path, {...otherOptions, headers: { ...defaultHeaders, ...options.headers }}));
	});
}

/*
 * Fetch a file from Google Drive
 */
function fetchDoc(id: string): Promise<types.Doc> {
	const content = driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/${id}?alt=media`
	}).then(({ body }) => JSON.parse(decodeURIComponent(escape(body))));
	const metadata = driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/${id}?fields=id,name,starred,parents,version,createdTime,modifiedTime,owners,shared,md5Checksum,size,headRevisionId,modifiedByMeTime,lastModifyingUser,permissions,capabilities,webContentLink,webViewLink`
	}).then(({ result }) => normalizeDoc(result));
	return Promise.all([content, metadata]).then(([content, metadata]) => ({
		...metadata,
		content
	}));
}

/*
 * Fetch user's docs from Google Drive
 */
function fetchDocs(): Promise<types.Doc[]> {
	return driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/?q=mimeType='application/vnd.ddoc' and trashed=false&fields=files(id,name,starred,parents,version,createdTime,modifiedTime)&orderBy=modifiedTime desc`
	}).then(({ result }) => result.files.map(file => normalizeDoc(file)));
}

/*
 * Fetch a doc's revision history from Google Drive
 */
function fetchRevisions(id: string): Promise<types.DriveRevision[]> {
	return driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/${id}/revisions?fields=revisions(id,modifiedTime,lastModifyingUser(displayName,me),size)`
	}).then(({ result }) =>
		result.revisions.reverse().map(revision => normalizeRevision(revision))
	);
}

/*
 * Fetch a doc revision content from Google Drive
 */
function fetchRevision(
	docId: string,
	revisionId: string
): Promise<types.DriveRevision[]> {
	return driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/${docId}/revisions/${revisionId}?alt=media`
	}).then(({ body }) => JSON.parse(decodeURIComponent(escape(body))));
}

/*
 * Create a doc to Google Drive
 */
function createDoc(): Promise<string> {
	return driveRequest({
		path: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=media',
		method: 'POST',
		headers: {
			'Content-Type': MIME_TYPE
		},
		body: DOC_TEMPLATE
	}).then(({ result }) => result.id);
}

/*
 * Create a doc to Google Drive
 */
function createRevision(doc: types.Doc): types.DriveRevision {
	return normalizeRevision({
		id: doc.headRevisionId,
		lastModifyingUser: doc.lastModifyingUser,
		modifiedTime: doc.modifiedTime,
		size: doc.size
	});
}

/*
 * Save doc to Google Drive
 */
function updateDoc(
	doc: types.Doc,
	text?: string = ''
): Promise<types.DriveFile> {
	const { id, name, content, modifiedTime } = doc;
	const { state } = window.history;
	const parent = state && state.folderId ? state.folderId : null;
	// const { base64 } = require('./thumbnail');
	const updateContent = driveRequest({
		path: `https://www.googleapis.com/upload/drive/v3/files/${id}?uploadType=media`,
		method: 'PATCH',
		headers: {
			'Content-Type': MIME_TYPE
		},
		body: JSON.stringify(content, null, 2)
	});
	const updateMetadata = driveRequest({
		path: `https://www.googleapis.com/drive/v3/files/${id}?fields=version,createdTime,modifiedTime,md5Checksum,size,headRevisionId,modifiedByMeTime,lastModifyingUser(displayName,me)${
			parent ? '&addParents=' + parent : ''
		}`,
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		body: {
			name: `${name}.ddoc`,
			contentHints: {
				// thumbnail: {
				// 	image: base64,
				// 	mimeType: 'image/png'
				// },
				indexableText: text
			},
			modifiedTime
		}
	}).then(({ result }) => normalizeDoc(result));
	return Promise.all([updateContent, updateMetadata]).then(
		([contentResponse, metadataResponse]) => metadataResponse
	);
}

/*
 * Upload file to Google Drive
 */
export function uploadFile(file: File): Promise<void> {
	const { state } = window.history;
	const parent = state && state.folderId ? state.folderId : null;
	return driveRequest({
		path: `https://www.googleapis.com/upload/drive/v3/files?uploadType=media&fields=id,name,webContentLink,mimeType&${
			parent ? '?addParents=' + parent : ''
		}`,
		method: 'POST',
		headers: {
			'Content-Type': file.type || 'image/png'
		},
		body: file
	}).then(({ result }) => result.webContentLink);
}

// /*
//  * Start watching a file on Google Drive
//  */
// function watchDoc(id: string): Promise<Function> {
// 	return driveRequest({
// 		path: `https://www.googleapis.com/drive/v3/files/${id}/watch`,
// 		method: 'POST',
// 		body: {
// 			id,
// 			type: 'web_hook',
// 			address: 'https://api.commonlayer.com/drive/watch',
// 			expiration: Date.now() + 1000 * 60 * 60 * 6
// 		}
// 	})
// 		.then(({ resourceId }) => () => unwatchDoc(id, resourceId))
// 		.catch(error => () => null);
// }

// /*
//  * Stop watching a file on Google Drive
//  */
// function unwatchDoc(id: string, resourceId: string): Promise<void> {
// 	return driveRequest({
// 		path: 'https://www.googleapis.com/drive/v3/channels/stop',
// 		method: 'POST',
// 		body: {
// 			id,
// 			resourceId
// 		}
// 	});
// }

/*
 * Connect to AWS IoT topic
 */
export function subscribeToTopic(id: string): Promise<types.AWSIoTDevice> {
	return new Promise((resolve, reject) => {
		if (!navigator.onLine) return reject(new Error('No network connection'));
		AWS.config.credentials.get(error => {
			if (error) return reject(error);
			const {
				accessKeyId,
				secretAccessKey: secretKey,
				sessionToken
			} = AWS.config.credentials;
			const client = device({
				protocol: 'wss',
				accessKeyId,
				secretKey,
				sessionToken,
				clientId: `${Math.floor(Math.random() * 1000000 + 1)}`,
				host: 'a3l33xu2mst5lb.iot.us-east-1.amazonaws.com'
			});
			client.on('connect', () => {
				client.subscribe(id, { qos: 1 });
			});
			client.on('error', error => {
				console.error(error);
			});
			client.on('offline', () => {
				// client.connect();
			});
			client.on('close', () => {
				client.end();
			});
			resolve(client);
		});
	});
}

/*
 * Wrap the Doc component and provide user props
 */
export function withAuth(WrappedComponent: React.ComponentType<any>) {
	return class extends React.Component<
		types.withAuthProps,
		types.withAuthState
	> {
		state = {
			user: INITIAL_STATE.user,
			docs: INITIAL_STATE.docs,
			error: null,
			loading: true
		};

		async componentDidMount(): Promise<void> {
			const cachedUser = getUserCache();
			this.setState({ user: cachedUser });
			try {
				const user = await initializeGoogle();
				this.setState({ user, error: null, loading: false });
				setUserCache(user);
				this.fetchDocs();
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
			}
		}

		handleLogin = async (): Promise<void> => {
			try {
				const user = await signInWithGoogle();
				this.setState({ user, error: null, loading: false });
				this.fetchDocs();
			} catch (error) {
				console.error(error);
			}
		};

		handleLogout = (): void => {
			signOutWithGoogle();
			this.setState({ user: INITIAL_STATE.user, error: null });
		};

		fetchDocs = async (): Promise<void> => {
			try {
				const docs = await fetchDocs();
				this.setState({ docs });
			} catch (error) {
				console.error(error);
			}
		};

		render() {
			const { user, docs, error, loading } = this.state;
			if (error) return <GenericError error={createError(error)} />;
			return (
				<React.Fragment>
					<UserMenu
						user={user}
						handleLogin={this.handleLogin}
						handleLogout={this.handleLogout}
						loading={loading}
					/>
					{!loading && (
						<WrappedComponent
							user={user}
							docs={docs}
							handleLogin={this.handleLogin}
							handleLogout={this.handleLogout}
							loading={loading}
						/>
					)}
				</React.Fragment>
			);
		}
	};
}

/*
 * Wrap the Doc component and provide doc, docSuggestions, and tagSuggestions props
 */
export function withDoc(WrappedComponent: React.ComponentType<any>) {
	return class extends React.Component<types.withDocProps, types.withDocState> {
		state = {
			doc: INITIAL_STATE.doc,
			revisions: INITIAL_STATE.revisions,
			error: null,
			loading: true
		};

		id = window.location.pathname.substring(1);

		timer: number = 0;

		async componentDidMount() {
			if (this.id.length === 0) {
				this.id = await createDoc();
				const { state } = queryStringToObject(window.location.search);
				window.history.pushState(
					state,
					'Untitled - Dynamic Docs',
					`${window.location.origin}/${this.id}`
				);
			}
			const cachedDoc = getDocCache(this.id);
			this.setState({ doc: cachedDoc });
			this.fetchDoc();
			this.fetchRevisions();
		}

		async componentDidUpdate(prevProps: types.withDocProps) {
			if (prevProps.user !== this.props.user) {
				this.fetchDoc();
			}
		}

		fetchDoc = async () => {
			try {
				const doc = await fetchDoc(this.id);
				if (
					this.state.doc.modifiedTime
						? doc.modifiedTime >= this.state.doc.modifiedTime
						: true
				) {
					this.setState({
						doc,
						error: null,
						loading: false
					});
					setDocCache(doc);
				} else {
					this.setState({ loading: false });
				}
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
			}
		};

		fetchRevisions = async (): Promise<void> => {
			try {
				const revisions = await fetchRevisions(this.id);
				this.setState({ revisions });
			} catch (error) {
				console.error(error);
				this.setState({ loading: false });
			}
		};

		fetchRevision = async (revision: types.DriveRevision): Promise<void> => {
			try {
				const content = await fetchRevision(this.id, revision.id);
				const doc = {
					...this.state.doc,
					headRevisionId: revision.id,
					modifiedTime: revision.modifiedTime,
					content
				};
				this.setState({ doc });
			} catch (error) {
				console.error(error);
			}
		};

		updateDoc = async (doc: types.Doc, text: string): Promise<void> => {
			this.setState({ doc, error: null });
			setDocCache(doc);
			window.clearTimeout(this.timer);
			this.timer = window.setTimeout(async () => {
				try {
					const updatedDoc = await updateDoc(doc, text);
					const revision = createRevision(updatedDoc);
					this.setState({
						doc: { ...this.state.doc, ...updatedDoc },
						revisions: [
							revision,
							...this.state.revisions.filter(
								revision => revision.id !== updatedDoc.headRevisionId
							)
						],
						error: null
					});
				} catch (error) {
					console.error(error);
				}
			}, 1000);
		};

		shareDoc = (): void => {
			window.googleShareClient.setItemIds([this.id]);
			window.googleShareClient.showSettingsDialog();
		};

		locateDoc = (): void => {
			const parent = this.state.doc.parents[0];
			window.open(
				parent
					? `https://drive.google.com/drive/u/0/folders/${parent}`
					: `https://drive.google.com/open?id=${this.id}`,
				'_blank'
			);
		};

		render() {
			const { doc, revisions, error, loading } = this.state;
			const { user, docs, handleLogin } = this.props;
			if (error) {
				if (error.status === 401 || error.status === 404)
					return <Login handleLogin={handleLogin} />;
				return <GenericError error={createError(error)} />;
			}
			if (!doc.id) return null;
			return (
				<React.Fragment>
					<ShareMenu
						user={user}
						shareDoc={this.shareDoc}
						locateDoc={this.locateDoc}
					/>
					<RevisionsMenu
						revisions={revisions}
						doc={doc}
						fetchRevision={this.fetchRevision}
					/>
					<WrappedComponent
						doc={doc}
						docs={docs}
						revisions={revisions}
						user={user}
						updateDoc={this.updateDoc}
						shareDoc={this.shareDoc}
						loading={loading}
					/>
				</React.Fragment>
			);
		}
	};
}
