// @flow

import * as React from 'react';
import { Hotkey, Hotkeys, HotkeysTarget, Menu } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
// import './Menu.css';
import * as types from './types';

class MenuWithHotkey extends React.Component<
	types.MenuWithHotkeyProps,
	types.MenuWithHotkeyState
> {
	state = {
		open: false
	};

	renderHotkeys = (): React.Node => {
		return (
			<Hotkeys>
				<Hotkey
					global={true}
					allowInInput={true}
					combo={this.props.hotkey}
					label={this.props.name}
					onKeyDown={() => {
						this.setState({
							open: !this.state.open
						});
					}}
				/>
			</Hotkeys>
		);
	};

	render() {
		return (
			<Omnibar
				isOpen={this.state.open}
				items={this.props.items}
				onItemSelect={(item, event) => {
					item.onSelect();
					this.setState({ open: false });
				}}
				itemListRenderer={({ items, itemsParentRef, query, renderItem }) => {
					return (
						<Menu ulRef={itemsParentRef}>
							{items
								.filter(item =>
									item.name.toLowerCase().includes(query.toLowerCase())
								)
								.map(renderItem)}
						</Menu>
					);
				}}
				itemRenderer={(item, { handleClick, modifiers, query }) => {
					return (
						<Menu.Item
							active={modifiers.active}
							disabled={modifiers.disabled}
							label={item.label}
							key={item.name}
							onClick={handleClick}
							text={item.name}
						/>
					);
				}}
				itemListPredicate={(query, items) =>
					items.filter(item =>
						item.name.toLowerCase().includes(query.toLowerCase())
					)
				}
				noResults={<Menu.Item disabled={true} text="No results" />}
				onClose={() => {
					this.setState({ open: false });
				}}
				resetOnSelect
				resetonQuery
			/>
		);
	}
}

export default HotkeysTarget(MenuWithHotkey);
