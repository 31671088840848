'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = onArrowRight;

var _constants = require('./constants');

var _utils = require('./utils');
/**
 * Determines behavior if the caret is currently outside of an inline, while arrowing to the right
 *
 * @param {Event} event
 * @param {Editor} editor
 * @param {Next} next
 * @param {Object} opts
 * @return {Null | Editor}
 */


function handleArrowRightOutsideInline(event, editor, next, opts) {
  var isExtending = event.shiftKey;
  var hasStickyBoundaries = opts.hasStickyBoundaries; // We are outside of an inline and need to figure out if we are anywhere close to a sticky inline

  var isAtEndOfCurrentTextNode = editor.value.selection.focusOffset === editor.value.focusText.text.length;
  if (!isAtEndOfCurrentTextNode) return next();
  var textNodeIndex = editor.value.focusBlock.nodes.findIndex(function (node) {
    return node.key === editor.value.focusText.key;
  }) + 1;
  var upcomingNode = editor.value.focusBlock.nodes.get(textNodeIndex);

  if ((0, _utils.isInlineBanned)(upcomingNode, opts) || !hasStickyBoundaries || isExtending || upcomingNode.isVoid) {
    return next();
  }

  return editor.command(_utils.moveToStartOf, upcomingNode, event);
}
/**
 * Determines behavior if the caret is currently inside of an inline, while arrowing to the right
 *
 * @param {Event} event
 * @param {Editor} editor
 * @param {Next} next
 * @param {Object} opts
 * @return {Null | Editor}
 */


function handleArrowRightInsideInline(event, editor, next, opts) {
  var isExtending = event.shiftKey;
  var hasStickyBoundaries = opts.hasStickyBoundaries; // In normal slate inline world, these two boundaries are the true start/end of an Inline.
  // Since you can never actually move to the start or end of an inline (that's what we are fixing after all!)

  var isAtSecondToLastCharacter = editor.value.selection.focusOffset === editor.value.focusInline.text.length - 1; // Thanks to this very plugin, it's common to be in this editor.value where you are at the edge of an inline.

  var isAtLastCharacter = editor.value.selection.focusOffset === editor.value.focusInline.text.length;
  var inlineIndex = editor.value.focusBlock.nodes.findIndex(function (node) {
    return node.key === editor.value.focusInline.key;
  }) + 1;
  var upcomingNode = editor.value.focusBlock.nodes.get(inlineIndex); // We are on an edge on the inside of an inline.  If they don't want sticky boundaries, or if they are extending,
  // then it doesn't stick here.

  if (isAtLastCharacter && upcomingNode && hasStickyBoundaries && !isExtending) {
    return editor.command(_utils.moveToStartOf, upcomingNode, event);
  } // In this case they are attempting to leave an artifact so we should make sure that
  // Is a smooth process


  if (upcomingNode && editor.value.focusInline.text === _constants.ZERO_WIDTH_SPACE) {
    return editor.command(_utils.moveToStartOf, upcomingNode, event, 1);
  }

  if (isAtSecondToLastCharacter) {
    return editor.command(_utils.moveToEndOf, editor.value.focusInline, event);
  }
}
/**
 * Caret Manipulation logic
 *
 * @param {Event} event
 * @param {Editor} editor
 * @param {Next} next
 * @param {Object} opts
 * @return {Null}
 */


function onArrowRight(event, editor, next, opts) {
  if (event.ctrlKey) return next(); // In these cases we are actually inside the inline.

  if (editor.value.focusInline) {
    return handleArrowRightInsideInline(event, editor, next, opts);
  }

  return handleArrowRightOutsideInline(event, editor, next, opts);
}