// @flow

import * as React from 'react';
import type { Editor, Next } from '../../types';

export default function SoftBreak(
	{ shift }: { shift: boolean } = { shift: true }
) {
	return {
		onKeyDown(event: SyntheticKeyboardEvent<*>, editor: Editor, next: Next) {
			if (event.key === 'Enter') {
				if ((shift && event.shiftKey) || (!shift && !event.shiftKey)) {
					return editor.insertText('\n');
				}
			}
			return next();
		}
	};
}
