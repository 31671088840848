'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInlineBanned = isInlineBanned;
exports.moveToEndOf = moveToEndOf;
exports.moveToStartOf = moveToStartOf;
/**
 * Return true if settings bans the inline type, or it is void.
 *
 * @param {Inline} inline
 * @param {Object} opts
 * @return {Boolean}
 */

function isInlineBanned(inline, opts) {
  var allowedTypes = opts.allowedTypes,
      bannedTypes = opts.bannedTypes; // Something crazy happened, there is no inline, or somehow focus inline is not an inline.

  if (!inline || inline.object !== 'inline' || inline.isVoid) return true; // The inline we are working with isn't allowed by user config.

  if (allowedTypes && !allowedTypes.includes(inline.type)) return true;
  if (bannedTypes.includes(inline.type)) return true;
  return false;
}
/**
 * Prevents default event behavior, and either collapses to or extends to the start
 * of a node
 *
 * @param {Editor} editor
 * @param {Node} node
 * @param {Event} event
 * @param {Number} offset (optional)
 * @return {Editor}
 */


function moveToEndOf(editor, node, event) {
  var offset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  event.preventDefault();
  return event.shiftKey ? editor.extendToEndOf(node).extend(offset) : editor.moveToEndOfNode(node).move(offset);
}
/**
 * Prevents default event behavior, and either collapses to or extends to the end
 * of a node
 *
 * @param {Editor} editor
 * @param {Node} node
 * @param {Event} event
 * @param {Number} offset (optional)
 * @return {Editor}
 */


function moveToStartOf(editor, node, event) {
  var offset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  event.preventDefault();
  return event.shiftKey ? editor.extendToStartOf(node).extend(offset) : editor.moveToStartOfNode(node);
}